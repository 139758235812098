import { Typography } from "@mui/joy";
import { Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  GroupedArticleByLevel,
  MenuItem,
  SelectedArticle,
  SelectedIngredient,
  SelectionMode,
} from "../../../../interfaces/Catalogue";
import "./LevelItem.scss";
import MultiSelectionList from "./types/MultiSelectionList/MultiSelectionList";
import MonoSelectionList from "./types/MonoSelectionList/MonoSelectionList";

interface LevelItemProps {
  level: GroupedArticleByLevel;
  typeOrder: number;
  handleOrderGenerated: (
    level: GroupedArticleByLevel,
    selectedArticles: SelectedArticle[]
  ) => void;
}

const LevelItem = (props: LevelItemProps) => {
  const { level, typeOrder, handleOrderGenerated } = props;
  const stackRef = useRef<HTMLDivElement>(null);

  const [selectedArticles, setSelectedArticles] = useState<SelectedArticle[]>(
    []
  );

  const updateSelectedArticles = (
    selecteMenuItem: MenuItem,
    selectedIngredients: SelectedIngredient[],
    eliminatedIngredients: SelectedIngredient[],
    mode: SelectionMode
  ) => {
    setSelectedArticles((prevSelectedArticles) => {
      // If the quantity is 0, remove the item from the selectedArticles
      if (selecteMenuItem.quantity === 0) {
        return prevSelectedArticles.filter(
          (article) => article.selecteMenuItem.id !== selecteMenuItem.id
        );
      }

      // Create a new selectedArticle
      const newSelectedArticle: SelectedArticle = {
        selecteMenuItem,
        selectedIngredients,
        eliminatedIngredients,
      };

      if (mode === SelectionMode.SINGLE) {
        return [newSelectedArticle];
      } else if (mode === SelectionMode.MULTIPLE) {
        // Check if item exists in the list
        const itemIndex = prevSelectedArticles.findIndex(
          (article) => article.selecteMenuItem.id === selecteMenuItem.id
        );

        if (itemIndex !== -1) {
          // Item exists, replace it
          const updatedArticles = [...prevSelectedArticles];
          updatedArticles[itemIndex] = newSelectedArticle;
          return updatedArticles;
        } else {
          // Item does not exist, add it
          return [...prevSelectedArticles, newSelectedArticle];
        }
      } else {
        throw new Error("Invalid selection mode");
      }
    });
  };

  useEffect(() => {
    handleOrderGenerated(level, selectedArticles);
  }, [selectedArticles]);

  return (
    <div id="LevelItem">
      <div className="sectionHeader row" style={{ alignItems: "center" }}>
        <Typography className="name">{level.name}</Typography>
        <Typography>
          {level.niveauMinSelection > 0 && (
            <div className="requiredLevel">
              obligatoire
              {level.niveauMinSelection > 1 &&
                ` (${level.niveauMinSelection}) `}
            </div>
          )}
        </Typography>
        <Typography>
          {level.niveauMaxSelection > 0 && level.niveauMinSelection !== 1 ? (
            <div className="maxSelectionLevel">
              <Typography>Max. {level?.niveauMaxSelection}</Typography>
            </div>
          ) : null}
        </Typography>
      </div>

      <Stack spacing={2} ref={stackRef} className="selectionList">
        {level?.niveauMaxSelection === 1 ? (
          <MonoSelectionList
            level={level}
            typeOrder={typeOrder}
            updateSelectedArticles={updateSelectedArticles}
          />
        ) : (
          <MultiSelectionList
            level={level}
            typeOrder={typeOrder}
            updateSelectedArticles={updateSelectedArticles}
          />
        )}
      </Stack>
    </div>
  );
};

export default LevelItem;

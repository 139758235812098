import { Card, CardActions, Link } from "@mui/joy";
import { Stack } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import localStorage from "redux-persist/es/storage";
import withNavigateHook from "../../../hooks/withNavigateHook";
import { setActiveRestaurant } from "../../../store/actions/actions";
import "../../../styles/cardsStyle.scss";
import { findCurrentShift } from "../../../utils/shopSubtittleFormat";
import SkeletonElement from "../../shared/Skeletons/SkeletonElement/SkeletonElement";

function RestaurantList(props: any) {
  const {
    id,
    nom,
    imageUrl,
    shop
  } = props;
  const navigate = useNavigate();
  const [curentShop, setCurentShop] = useState({});
  const [imageLoaded, setImageLoaded] = useState(false);
  const currentShift = findCurrentShift(props.shop);
  localStorage.setItem("shop", JSON.stringify(curentShop));
  
  const reduceTheName = (nom: string, lengthnum: number) => {
    return nom.split("").length > lengthnum
      ? `${nom.substring(0, lengthnum)}...`
      : nom;
  };

  const handleClick = () => {
    props.setActiveRestaurant(shop);
    setCurentShop(shop);
    navigate(`/restaurant/${id}`, { state: imageUrl });
  };

  const preventContextMenu = (e:any) => {
    e.preventDefault();
  
  };

  return (
    <div className="card-container ske">
      {/* <SkeletonElement type="full-block" /> */}
      <Card
        onClick={handleClick}
        variant="plain"
        sx={{
          height: { xs: "165px", md: "fit" },
          boxShadow: "md",
          cursor: "pointer",
          borderRadius: 12,
          overflow: "hidden",
          "--Card-padding": "0px",
          "--AspectRatio-radius": 0,
          "--CardCover-radius": 0,
          position: "relative",
          marginLeft: "-10px",
        }}
        style={{
          width: "350px",
          maxWidth: "100%",
        }}
        className={`cardDosContainer full-size ${props.isOpen ? "Online" : "Offline"}`}
      >
        <CardActions
          buttonFlex="0 1 120px"
          sx={{ position: "absolute", right: 0 }}
        >
          {/* IconButton component here */}
        </CardActions>

        <Link>
          <div className="card-img">
            <p className="status">{props.isOpen ? "" : "Fermé"}</p>
            <img
              src={imageUrl}
              alt=""
              style={{ objectFit: "cover", height: "100%" }}
              onLoad={() => setImageLoaded(true)}
              onContextMenu={preventContextMenu}
            />
          </div>
        </Link>
        <div className="redPartPseudoElement" onClick={handleClick}>
          <Stack className="cardInfo">
            <div className="card-header">
              <span className="status-dot"></span>
              <p className="restaurant-name">{reduceTheName(nom, 25)}</p>
            </div>

            <p className="card-footer">
              {currentShift?.isCurrentShift ? "Fermé" : "Ouvre"} à{" "}
              {currentShift?.isCurrentShift
                ? moment(currentShift?.close, "HH:mm").format("HH:mm")
                : moment(currentShift?.open, "HH:mm").format("HH:mm")}{" "}
              • {props.distance}Km • {props.tempsPrep} min{" "}
              {props.specialites ? "•" + props.specialites : null}
            </p>
          </Stack>
        </div>
        {!imageLoaded && <SkeletonElement type="img-block" />}
      </Card>
    </div>
  );
}
const mapStateToProps = (state: any) => {
  return {
    location: state.auth.location,
    homeLoading: state.shop.homeLoading,
    shops: state.shop.shops,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setActiveRestaurant: (restaurant: any) =>
      dispatch(setActiveRestaurant(restaurant)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(RestaurantList));

import { useEffect, useRef } from "react";
import {
  GroupedArticleByLevel,
  OrderItem,
  SelectedArticle,
} from "../../../../interfaces/Catalogue";
import LevelItem from "../LevelItem/LevelItem";

interface LevelsListProps {
  levels: GroupedArticleByLevel[];
  typeOrder: number;
  onOrderGenerated: (selectedArticles: OrderItem) => void;
  scrollToLevel: any;
}

const LevelList: React.FC<LevelsListProps> = ({
  levels,
  typeOrder,
  onOrderGenerated,
  scrollToLevel,
}) => {
  const levelRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  useEffect(() => {
    scrollToLevel.current = (id: string) => {
      const element = levelRefs.current[id];
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }, 0);
      }
    };
  }, [scrollToLevel]);

  const handleOrderGenerated = (
    level: GroupedArticleByLevel,
    selectedArticles: SelectedArticle[]
  ) => {
    onOrderGenerated({ level, selectedArticles: selectedArticles });
  };

  return (
    <div>
      {levels.map((level) => (
        <div key={level.id} ref={(el) => (levelRefs.current[level.id] = el)}>
          <LevelItem
            key={level.id}
            level={level}
            typeOrder={typeOrder}
            handleOrderGenerated={handleOrderGenerated}
          />
        </div>
      ))}
    </div>
  );
};

export default LevelList;

import * as React from "react";

const TrashIcon = ({
  color = "#E05F03", // default color
  ...props
}: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { color?: string }) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} y={0.5} width={23} height={23} rx={11.5} fill="white" />
    <rect x={0.5} y={0.5} width={23} height={23} rx={11.5} stroke="#D0D5DD" />
    <path
      d="M8.25 8.66634H7.625V17.333C7.625 17.6866 7.7567 18.0258 7.99112 18.2758C8.22554 18.5259 8.54348 18.6663 8.875 18.6663H15.125C15.4565 18.6663 15.7745 18.5259 16.0089 18.2758C16.2433 18.0258 16.375 17.6866 16.375 17.333V8.66634H8.25ZM10.75 16.6663H9.5V10.6663H10.75V16.6663ZM14.5 16.6663H13.25V10.6663H14.5V16.6663ZM14.8862 6.66634L13.875 5.33301H10.125L9.11375 6.66634H6.375V7.99967H17.625V6.66634H14.8862Z"
      fill={color}
    />
  </svg>
);

export default TrashIcon;

import * as React from "react";
const FilledLocationIcon = ({
  color = "#E05F03", // default color
  ...props
}: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { color?: string }) => (
  <svg
    width={19}
    height={25}
    viewBox="0 0 19 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.875 24.1836C2.03125 14.3398 0.8125 13.3086 0.8125 9.65234C0.8125 4.68359 4.79688 0.652344 9.8125 0.652344C14.7812 0.652344 18.8125 4.68359 18.8125 9.65234C18.8125 13.3086 17.5469 14.3398 10.7031 24.1836C10.2812 24.8398 9.29688 24.8398 8.875 24.1836ZM9.8125 13.4023C11.875 13.4023 13.5625 11.7617 13.5625 9.65234C13.5625 7.58984 11.875 5.90234 9.8125 5.90234C7.70312 5.90234 6.0625 7.58984 6.0625 9.65234C6.0625 11.7617 7.70312 13.4023 9.8125 13.4023Z"
      fill={color}
    />
  </svg>
);
export default FilledLocationIcon;

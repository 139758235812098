import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "../../../../../images/CloseIcon_btn.svg";
import EmptyCart from "../../../../../images/empty-cartIcon.svg";
import { RestaurantCart } from "../../../../../interfaces/Catalogue";
import { getTotalOrder } from "../../../../../utils";
import CartElements from "../CartElements/CartElements";
import CartHeader from "../CartHeader/CartHeader";
import "../cartModalStyles.scss";

interface CartDataProps {
  restaurantCart: RestaurantCart;
  handleCommand: (restaurantId: number) => void;
  setShowCommandDetails: (value: boolean) => void;
  ShowCommandDetails: boolean
}

export const CartData = (props: CartDataProps) => {
  const {
    handleCommand,
    setShowCommandDetails,
    ShowCommandDetails,
    restaurantCart,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { restaurant, categories } = restaurantCart;
  const restaurantId = restaurant?.id;
  const totalAmount = getTotalOrder(categories)
  const [showEmptyPanier, setShowEmptyPanier] = useState(false);

  const navigateToShope = () => {
    const isRestaurantsPage =
      location.pathname === `/restaurant/${restaurantId}`;
    if (!isRestaurantsPage) {
      navigate(`/restaurant/${restaurantId}`);
    } else {
      setShowCommandDetails(!ShowCommandDetails);
    }
  };

  const CloseModal = () => {
    setShowEmptyPanier(!showEmptyPanier);
    document
      .querySelector(".CommandInfo")
      ?.classList.remove(".showCommandDetails");
  };

  return (
    <div
      className={`CartData CartElementSection ${
        categories === undefined ||
        categories?.length === 0
          ? "noItems"
          : ""
      }`}
    >
      <div className="closeBtn">
        <img
          className=""
          src={CloseIcon}
          alt="CloseIcon"
          onClick={() => {
            CloseModal();
            setShowCommandDetails(!ShowCommandDetails);
            localStorage.setItem("redirectedToPrevPage", "false");
          }}
        />
      </div>
      {categories === undefined ||
      categories?.length === 0 ? (
        <div className="panier-wrapper">
          <img className="EmptyCart" src={EmptyCart} alt="EmptyCart" />
          <p>Aucun Article ajouté</p>
          <span>Ajoutez des articles, ils seront affichés ici.</span>
        </div>
      ) : (
        <div>
          <div className="CartComponents">
            <CartHeader restaurantCart={restaurantCart} />
            <CartElements
              restaurantCart={restaurantCart}
              setShowCommandDetails={setShowCommandDetails}
            />
          </div>
          <div className="CartFooter">
            <button
              className="btn-command"
              onClick={() => {
                localStorage.setItem(
                  "restaurantId",
                  String(restaurant?.id)
                );
                handleCommand(restaurant?.id);
              }}
            >
              Commander • {totalAmount}€
            </button>
            <button onClick={navigateToShope} className="btn-add-articles">
              Ajouter article(s)
            </button>
          </div>
        </div>
      )}
    </div>
  );
};


export default CartData;

import { LoadScript } from "@react-google-maps/api";
import { GoogleOAuthProvider } from "@react-oauth/google";
import moment from "moment";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import AppLoading from "./components/shared/Loaders/HomeLoading/AppLoading";
import { ConfigProvider } from "./context/ConfigContext";
import AppRoutes from "./navigation";
import configureStore from "./store/store";
import "./style.scss";

function App() {
  moment.locale("fr");
  const store = configureStore({});
  const persistor = persistStore(store);
  
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleOAuthProvider clientId="634820308489-qa4lnj89noo3b9a4gejp0bgob0t0to61.apps.googleusercontent.com">
          <LoadScript
            libraries={["places"]}
            id={"TheOneAndOnlyGmaps"}
            language={"fr"}
            region={"FR"}
            googleMapsApiKey={"AIzaSyBV9I7IJhaLGsNn4C4yjTwzTgdtlYpIZJU"}
            onLoad={() => console.log("onloaddd")}
            loadingElement={<AppLoading />}
          >
            <ConfigProvider>
              <div className="container">
                <AppRoutes />
              </div>
            </ConfigProvider>
          </LoadScript>
        </GoogleOAuthProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;

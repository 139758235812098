import * as React from "react";
const RightArrowIcon = ({
  color = "#E05F03", // default color
  ...props
}: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { color?: string }) => (
  <svg
    width={8}
    height={12}
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.5 11L6.5 6L1.5 1"
      stroke={color}
      strokeWidth={1.66667}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default RightArrowIcon;

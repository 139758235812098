// categoriesSagas.ts
import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { catalogueApi } from '../../../services/catalogueApi';
import { getCategoriesFailure, getCategoriesSuccess } from './categoriesActions';
import { CategoriesActionTypes } from './categoriesActionTypes';
import { Categories } from './categoriesInterfaces';

function* fetchCategoriesSaga(action: { type: string; payload: { urlAzure: string } }) {
  const { urlAzure } = action.payload

  try {
    const response: AxiosResponse<Categories> = yield call(catalogueApi.getCategories, urlAzure);
    yield put(getCategoriesSuccess(response.data.data.result));
  } catch (error: any) {
    const { response } = error;
    console.log('error response', response);
    const errorCodeFI = response?.data?.failureResponse?.codeFI?.code;
    const errorCodeReq = response?.status;

    yield put(getCategoriesFailure(errorCodeFI || errorCodeReq));
  }
}


export default function* categoriesSaga() {
  yield takeLatest(CategoriesActionTypes.GET_CATEGORIES_REQUEST, fetchCategoriesSaga);
}

// categoriesActions.ts
import { Category } from '../../../interfaces/Catalogue';
import { CategoriesActionTypes } from './categoriesActionTypes';
import {
  GetCategoriesFailureAction,
  GetCategoriesRequestAction,
  GetCategoriesSuccessAction
} from './categoriesInterfaces';

export const getCategoriesRequest = (urlAzure:string): GetCategoriesRequestAction => ({
  type: CategoriesActionTypes.GET_CATEGORIES_REQUEST,
  payload: { urlAzure},
});

export const getCategoriesSuccess = (
  categories: Category[]
): GetCategoriesSuccessAction => ({
  type: CategoriesActionTypes.GET_CATEGORIES_SUCCESS,
  payload: categories,
});

export const getCategoriesFailure = (
  error: string
): GetCategoriesFailureAction => ({
  type: CategoriesActionTypes.GET_CATEGORIES_FAILURE,
  payload: error,
});


import React from 'react'
import { Chip, Typography } from "@mui/joy";

interface AllergenChipProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const AllergenChip: React.FC<AllergenChipProps> = ({ onClick }) => {

  return (
    <Chip
    color="primary"
    size="md"
    variant="outlined"
    sx={{ padding: " 0 5px" }}
    startDecorator={
      <svg
        width="17"
        height="17"
        viewBox="0 0 12 12"
        fill="red"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#007FFF"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 1.09091C3.28878 1.09091 1.09091 3.28878 1.09091 6C1.09091 8.71122 3.28878 10.9091 6 10.9091C8.71122 10.9091 10.9091 8.71122 10.9091 6C10.9091 3.28878 8.71122 1.09091 6 1.09091ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z"
        />{" "}
        {/*fill="#026AA2" */}
        <path
          fill="#007FFF"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.99997 2.90891C5.79914 2.90891 5.63633 3.07172 5.63633 3.27255C5.63633 3.47338 5.79914 3.63619 5.99997 3.63619C6.2008 3.63619 6.3636 3.47338 6.3636 3.27255C6.3636 3.07172 6.2008 2.90891 5.99997 2.90891ZM4.90906 3.27255C4.90906 2.67006 5.39747 2.18164 5.99997 2.18164C6.60246 2.18164 7.09088 2.67006 7.09088 3.27255C7.09088 3.87504 6.60246 4.36346 5.99997 4.36346C5.39747 4.36346 4.90906 3.87504 4.90906 3.27255Z"
        />
        <path
          fill="#007FFF"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.47013 8.33014C3.94943 8.56419 3.33758 8.33182 3.10352 7.81112L2.10168 5.58236C1.86763 5.06166 2.1 4.44981 2.6207 4.21576V4.21576C3.14139 3.9817 3.75325 4.21407 3.9873 4.73477L4.98914 6.96353C5.2232 7.48423 4.99083 8.09608 4.47013 8.33014V8.33014Z"
        />
        <path
          fill="#007FFF"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.73418 4.81359C3.67895 4.75077 3.68988 4.65402 3.75775 4.60511L4.05283 4.39243C4.11831 4.34523 4.20981 4.36127 4.25534 4.42792V4.42792C4.2996 4.49271 4.28365 4.58104 4.21951 4.62625L3.92223 4.83585C3.86307 4.87756 3.78197 4.86796 3.73418 4.81359V4.81359Z"
        />
        <path
          fill="#007FFF"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.27015 5.86682C4.21875 5.79358 4.24491 5.69174 4.32524 5.65233L4.61666 5.50938C4.66576 5.4853 4.72512 5.50416 4.75132 5.55216V5.55216C4.77417 5.59404 4.76499 5.64615 4.72921 5.6777L4.48577 5.8924C4.42064 5.94984 4.32003 5.93791 4.27015 5.86682V5.86682Z"
        />
        <path
          fill="#007FFF"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.70377 7.04062C4.65378 6.96916 4.67892 6.86998 4.75692 6.83098L5.14149 6.63868C5.1892 6.61482 5.24723 6.63301 5.27278 6.67983V6.67983C5.29594 6.72229 5.28507 6.77526 5.24706 6.80516L4.90912 7.071C4.84465 7.12172 4.7508 7.10783 4.70377 7.04062V7.04062Z"
        />
        <path
          fill="#007FFF"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.7628 5.8024C1.73938 5.75948 1.75145 5.70584 1.791 5.67709L2.15059 5.41561C2.21201 5.37095 2.29824 5.38617 2.34065 5.44917V5.44917C2.38584 5.51626 2.36176 5.60779 2.28941 5.64397L1.89174 5.84282C1.84489 5.86625 1.7879 5.84838 1.7628 5.8024V5.8024Z"
        />
        <path
          fill="#007FFF"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.21675 6.77544C2.19374 6.73327 2.20393 6.6807 2.24102 6.65018L2.57156 6.37819C2.63987 6.32198 2.74189 6.33763 2.79021 6.41173V6.41173C2.83865 6.48601 2.81158 6.58592 2.73226 6.62558L2.3494 6.81702C2.3012 6.84113 2.24257 6.82274 2.21675 6.77544V6.77544Z"
        />
        <path
          fill="#007FFF"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.73934 7.90517C2.71631 7.86296 2.72659 7.81034 2.76381 7.7799L3.11569 7.49217C3.19389 7.42822 3.31164 7.4579 3.35018 7.55127V7.55127C3.38031 7.62425 3.34896 7.7081 3.27835 7.74341L2.8718 7.9467C2.82366 7.97077 2.76511 7.95241 2.73934 7.90517V7.90517Z"
        />
        <path
          fill="#007FFF"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.65352 4.42384C9.98825 4.48994 10.206 4.81487 10.1399 5.1496L9.98767 5.92066C9.84273 6.65468 9.27065 7.22956 8.53735 7.37808L7.96887 7.49323C7.72443 7.54273 7.53374 7.73436 7.48543 7.97903L7.37307 8.54807C7.22813 9.28209 6.65605 9.85697 5.92275 10.0055L5.15244 10.1615C4.81804 10.2292 4.49205 10.0131 4.42432 9.67867V9.67867C4.35659 9.34427 4.57277 9.01828 4.90717 8.95055L5.67747 8.79453C5.92191 8.74502 6.1126 8.55339 6.16091 8.30872L6.27327 7.73968C6.41821 7.00566 6.99029 6.43078 7.72359 6.28226L8.29208 6.16711C8.53651 6.11761 8.7272 5.92598 8.77551 5.68131L8.92777 4.91024C8.99386 4.57552 9.31879 4.35775 9.65352 4.42384V4.42384Z"
        />
        <path
          fill="#007FFF"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.45595 8.12156C4.52178 8.07856 4.61049 8.10946 4.63534 8.18406L4.71739 8.43031C4.7322 8.47475 4.71327 8.52344 4.67233 8.5462V8.5462C4.62492 8.57256 4.5651 8.5551 4.53933 8.50736L4.41601 8.27897C4.38638 8.22409 4.40374 8.15567 4.45595 8.12156V8.12156Z"
        />
        <path
          fill="#007FFF"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.39309 3.97195C2.43851 3.94669 2.49523 3.95521 2.53123 3.99268L2.71347 4.18236C2.79878 4.27116 2.7712 4.41757 2.65942 4.46924V4.46924C2.55983 4.51528 2.44263 4.46184 2.41206 4.35647L2.33879 4.10385C2.32393 4.0526 2.34645 3.99788 2.39309 3.97195V3.97195Z"
        />
      </svg>
    }
  >
    <Typography
      level="body-xs"
      fontWeight={"bold"}
      color="primary"
      sx={{ cursor: "pointer" }}
      onClick={(e:any) => {
        e.stopPropagation();
        if (onClick) onClick(e);
      }}
    >
      Allergènes
    </Typography>
  </Chip>
  )
}

export default AllergenChip
import React, { Fragment } from "react";
import { Skeleton, Stack, FormControlLabel } from "@mui/material";

const LevelSkeleton = () => {
  return (
    <div>
      <Skeleton
        className="row"
        style={{ alignItems: "center", height: "40px" }}
      >
        {/* <Skeleton variant="text" width={200} height={24} sx={{ fontFamily: "Poppins,sans-serif", fontWeight: 700, backgroundColor: "#F6F7F9" }} />
        <Skeleton variant="text" width={100} height={20} sx={{ marginLeft: 8 }} />
        <Skeleton variant="text" width={100} height={20} sx={{ marginLeft: 8 }} /> */}
      </Skeleton>

      <Stack className="menuProducts" spacing={2} >
        {[...Array(2)].map((_, index) => (
          <div key={index} style={{paddingRight:"25px"}}>
            <Fragment>
              <div
                className="componentContainer full-width column"
                style={{ alignItems: "center" }}
              >
                <div className="componentRow full-width">
                  <FormControlLabel
                    value=""
                    control={
                      <Skeleton variant="circular" width={24} height={24} />
                    }
                    label={
                      <div>
                        <Skeleton variant="text" width={100} height={24} />
                        <Skeleton variant="text" width={50} height={20} />
                      </div>
                    }
                    labelPlacement="start"
                    style={{
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  />
                </div>
              </div>
            </Fragment>
          </div>
        ))}
      </Stack>
    </div>
  );
};

export default LevelSkeleton;

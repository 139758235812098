import AnimatedFoodPreparation from "../../images/AnimatedFoodPrepartion.gif";
import Loader from "../shared/Loaders/Loader/Loader";
import "./PaymentStyles.scss";

export default function ProcessingCommand(props:any) {
  return (
    <div className="ProcessingCommand">
      <div className="AnimatedGif">
        <img src={AnimatedFoodPreparation} alt="" />
      </div>
      <div className="commandInfo">
        <div className="commandHeader">
          <p>Prise en charge en cours</p>
        </div>
        <div className="CommandSteps">
          <div className="restaurant-name step-box">
            <Loader loadingTimeout={3000} />
            <div>
              <p className="label">{props.resturant}</p>
              <p className="value">{props.distance}</p>
            </div>
          </div>
          <div className="delivry-type step-box">
            <Loader loadingTimeout={3500} />

            <div>
              <p className="label">{props.placement}</p>
              <p className="value">{props.tempsPrep} min</p>
            </div>
          </div>
          <div className="payment-type step-box">
            <Loader loadingTimeout={4000} />

            <div>
              <p className="label">Paiement</p>
              <p className="value">Transaction en cours ...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

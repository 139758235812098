import dayjs from "dayjs";
import {
    Category,
    CATEGORY_ITEM_TYPE,
    CategoryItem,
    GroupedArticleByLevel,
    Ingredient,
    MenuItem,
    SelectedIngredient,
    TVA,
    TypeOrderPrice
} from "../interfaces/Catalogue";

export const MODE_ONSITE = 1;
export const MODE_TAKEAWAY = 2;
export const MODE_DRIVE = 3;
export const MODE_DELIVERY = 4;

// PRICE:
export const findPriceByTypeOrder = (
    typeOrderCategory: TypeOrderPrice[],
    typeOrder: number
): number | undefined => {
    const item = typeOrderCategory?.find(
        (orderCategory) => orderCategory.type === typeOrder
    );
    return item?.price || 0;
};

export const getPriceByTypeOrderForComposition = (typeOrder: number, articleComposition: Ingredient): number => {
    switch (typeOrder) {
        case MODE_ONSITE: // Sur Place
            return articleComposition.priceOnSite ?? 0;
        case MODE_TAKEAWAY: // A Emporter
            return articleComposition.priceEmporter ?? 0;
        case MODE_DELIVERY: // Livraison
            return articleComposition.priceDelivery ?? 0;
        default:
            return 0;
    }
}

// TVA:
export const getTvaByOrderType = (orderType: number, tvaObj: any): TVA => {
    console.log("🚀 ~ getTvaByOrderType ~ tvaObj:", tvaObj)
    switch (orderType) {
        case MODE_ONSITE:
            return tvaObj.tvaOnSite;
        case MODE_TAKEAWAY:
            return tvaObj.tvaEmporter;
        case MODE_DELIVERY:
            return tvaObj.tvaDelivery;
        default:
            throw new Error('Invalid order type');
    }
};

export const groupMenuItemsByLevel = (
    menuItems: MenuItem[]
): GroupedArticleByLevel[] => {
    const levelMap: { [levelId: number]: GroupedArticleByLevel } = {};


    menuItems.forEach(item => {
        const { levelId, level } = item;

        if (!levelMap[levelId]) {
            levelMap[levelId] = { ...level, menuItems: [] };
        }

        if (!item.isNotImportant) {
            levelMap[levelId].menuItems!.push(item);
        }
    });


    const sortedItems = Object.values(levelMap).sort((a, b) => a.orderIndex - b.orderIndex);
    return Object.values(sortedItems);
};

export const getOrderTypeText = (typeOrder: number): string | null => {
    if (!typeOrder) return null;

    switch (typeOrder) {
        case MODE_TAKEAWAY:
            return "À Emporter"; // Takeaway
        case MODE_ONSITE:
            return "Sur place"; // Onsite
        case MODE_DELIVERY:
            return "Livraison"; // Delivery
        case MODE_DRIVE:
            return "Drive"; // Drive
        default:
            return "Mode inconnu"; // Unknown mode
    }
}

// Utility function to compare arrays (e.g., for checking if ingredients are the same)
export const arraysEqual = (arr1: SelectedIngredient[], arr2: SelectedIngredient[]): boolean => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((item, index) => item.id === arr2[index].id); // Adjust comparison based on actual properties
};

export const getTotalOrder = (categories: CategoryItem[]): number => {
    const calculatedTotal =
        categories?.length > 0
            ? categories.reduce((total: number, category) => {
                return (
                    total + (category?.unitPrice || 0) * (category?.quantity || 0)
                );
            }, 0)
            : 0;

    return parseFloat(calculatedTotal.toFixed(2))
}

export const getCategoryItemType = (type: string) => {
    return {
        isMenu: type === CATEGORY_ITEM_TYPE.MENU,
        isArticle: type === CATEGORY_ITEM_TYPE.ARTICLE,
    };
};

export const filterItemsByTypeOrder = (categories: Category[], typeOrder: number): Category[] => {
    return categories.filter((category) =>
        category.typeOrderFamily.some((family) => family.type === typeOrder)
        && category.categoryArticlesCount > 0 || category.categoryMenusCount > 0
    );
};

export const selectedTimeRightISO = (timeString: any) => {
    if (!timeString) return null;
    const todayDate = dayjs().format("YYYY-MM-DD");
    const combinedDateTimeString = `${todayDate}T${timeString}`;
    const dateTime = dayjs(combinedDateTimeString);
    return combinedDateTimeString;
};

export const getDiningModeLabel = (diningMode: number): string => {
    const diningModeLabels: Record<number, string> = {
        [MODE_ONSITE]: "sur Place",
        [MODE_TAKEAWAY]: "à emporter",
        [MODE_DRIVE]: "Drive",
        [MODE_DELIVERY]: "Livraison",
    };

    return diningModeLabels[diningMode] || "Mode inconnu";
}
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import { IconButton } from "@mui/material";
import { MenuItem } from "../../../../../../../../interfaces/Catalogue";
import "./QuantityCounter.scss";

interface QuantityCounterProps {
  index: number;
  menuItem: MenuItem;
  maxSelection: number;
  quantityArray: any[];
  updateQuantityArray: (
    newQ: number,
    index: number,
    menuItem: MenuItem
  ) => void;
  increaseDisabled: boolean;
}

const QuantityCounter = (props: QuantityCounterProps) => {
  const {
    index,
    menuItem,
    maxSelection,
    quantityArray,
    updateQuantityArray,
    increaseDisabled,
  } = props;

  return (
    <div id={"product" + menuItem?.id} className="multiselectionCounter row">
      <span
        className="row all-center ingredientContainer"
        style={{ width: quantityArray?.[index] > 0 ? "50px" : "0px" }}
      >
        <IconButton
          size="small"
          disabled={quantityArray?.[index] === 0}
          onClick={() => {
            if (quantityArray[index] > 0) {
              updateQuantityArray(quantityArray[index] - 1, index, menuItem);
            }
          }}
          className={quantityArray?.[index] === 0 ? "hide" : ""}
        >
          <RemoveCircleOutlineRoundedIcon fontSize="medium" />
        </IconButton>

        {quantityArray[index] > 9
          ? quantityArray[index]
          : "0" + quantityArray[index]}
      </span>

      <IconButton
        size="small"
        disabled={increaseDisabled}
        onClick={() => {
          if (quantityArray[index] < maxSelection) {
            updateQuantityArray(quantityArray[index] + 1, index, menuItem);
          }
        }}
        className={quantityArray?.[index] === maxSelection ? "hide" : ""}
      >
        <AddCircleOutlineRoundedIcon fontSize="medium" />
      </IconButton>
    </div>
  );
};

export default QuantityCounter;

import React from "react";
import { CategoryItem } from "../../../interfaces/Catalogue";
import "./CommandDetails.scss"

interface CommandDetailsProps {
  categoryItem: CategoryItem;
}

const CommandDetails = (props: CommandDetailsProps) => {
  const { categoryItem } = props;

  return (
    <div>
      {categoryItem?.order
        ? // Is Menu
          categoryItem?.order?.map((order) => {
            if (order?.selectedArticles?.length > 0) {
              return (
                <div className="menu-content">
                  <div className="menu-content-wrapper">
                    <div className="menu-list">
                      <p className="menuItemName">{order?.level?.name}</p>
                      {order?.selectedArticles?.map((article) => {
                        const { selectedIngredients, selecteMenuItem } =
                          article;
                        return (
                          <div
                            key={article.selecteMenuItem.id}
                            className="menusItemList"
                          >
                            <div className="SingleMenuList">
                              <p
                                className="menuSubName"
                                style={{ textTransform: "uppercase" }}
                              >
                                {selecteMenuItem?.quantity}{" "}
                                {selecteMenuItem?.name}
                              </p>
                              {selectedIngredients?.length > 0 && (
                                <p className="SubitemDescription">
                                  {selectedIngredients.map(
                                    (ingredient, index) => (
                                      <span key={index}>
                                        {ingredient?.quantity > 1 &&
                                          ingredient?.quantity}{" "}
                                        {ingredient?.ingredient?.name}
                                        {index < selectedIngredients.length - 1
                                          ? ", "
                                          : ""}
                                      </span>
                                    )
                                  )}
                                </p>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            }
          })
        : // Is Article
          categoryItem?.selectedIngredients &&
          categoryItem?.selectedIngredients?.length > 0 && (
            <div className="menu-content">
              <div className="menu-content-wrapper">
                <div className="menu-list">
                  <div className="menusItemList">
                    <div className="SingleMenuList">
                      <p
                        className="menuSubName"
                        style={{ textTransform: "uppercase" }}
                      ></p>

                      <p className="SubitemDescription">
                        {categoryItem?.selectedIngredients.map(
                          (ingredient, index) => (
                            <span key={index}>
                              {ingredient?.quantity > 1 && ingredient?.quantity}{" "}
                              {ingredient?.ingredient?.name}
                              {index <
                              (categoryItem?.selectedIngredients?.length || 0) -
                                1
                                ? ", "
                                : ""}
                            </span>
                          )
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
    </div>
  );
};

export default CommandDetails;

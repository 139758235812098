import { useDispatch, useSelector } from "react-redux";
import Bin from "../../../../../images/red_bin.svg";
import { RestaurantCart } from "../../../../../interfaces/Catalogue";
import { pushCategory } from "../../../../../store/actions/actions";
import CartSubElements from "../../../../shared/AppNavbar/CartModal/CartSubElements/CartSubElements";
import "../cartModalStyles.scss";

interface CartElementsProps {
  restaurantCart: RestaurantCart;
  setShowCommandDetails: (value: boolean) => void;
}

const CartElements = (props: CartElementsProps) => {
  const { restaurantCart, setShowCommandDetails } = props;
  const { restaurant, categories } = restaurantCart;
  const restaurantId = restaurant?.id;
  const savedCategories = useSelector(
    (state: any) => state.cart.savedCategories
  );
  const dispatch = useDispatch();

  const handleCancel = () => {
    let updatedCategories = { ...savedCategories };
    delete updatedCategories[restaurantId];
    dispatch(pushCategory(updatedCategories));
    setShowCommandDetails(false);
  };

  return (
    <div className="CartElements">
      <div className="CartsElements-listing">
        {categories?.map((categoryItem, index) => (
          <CartSubElements
            key={index}
            index={index}
            categoryItem={categoryItem}
            restaurantId={restaurantId}
          />
        ))}
        <div className="cancel-btn">
          <button className="cancel-btn" onClick={handleCancel}>
            <img src={Bin} alt="" />
            Annuler commande
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartElements;

import { AxiosResponse } from "axios";
import { put } from "redux-saga/effects";
import { shopApi } from "../../services/apiGate";
import { getFavoritesFiltredShopsSuccess, getFiltredShopsSuccess, updateHomeLoading } from "../actions/actions";

export function* getFilredShopsSaga(action: serviceShopModels.getFiltredShopBodyAction) {
    try {
        yield put(updateHomeLoading(true))

        const { body, companyId } = action;

        const response: AxiosResponse = yield shopApi.getFiltredShop(body, companyId);

        if (response.data) {
            let data: serviceShopModels.getFiltredShopResponse = response.data

            yield put(updateHomeLoading(false))
            yield put(getFiltredShopsSuccess(data.restaurants))
        }
    } catch (error: any) {
        yield put(updateHomeLoading(false))
    }
}

export function* addShopToFavoriteSaga(action: { idRestaurant: number, value:boolean,type: string }) {
    try {
        let response: AxiosResponse = yield shopApi.addShopToFavorite(action.idRestaurant,action.value)

        if (response.data) {
            let data = response.data
        }
    } catch (error: any) {}
}

export function* getFavoritesFilredShopsSaga(action: serviceShopModels.getFiltredShopBodyAction) {
    try {

        const { body, companyId } = action;

        const response: AxiosResponse = yield shopApi.getFiltredShop(body, companyId);

        if (response.data) {
            let data: serviceShopModels.getFiltredShopResponse = response.data
       
            yield put(getFavoritesFiltredShopsSuccess(data.restaurants))
        }
    } catch (error: any) {}
}
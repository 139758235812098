import axios from "axios";
import storage from "redux-persist/lib/storage";
import { OrderPayload } from "../interfaces/apiPayloads";


const MachineId = null;
const TypeMachine = 4;

export const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(
  async (config: any) => {
    const establishementToken = await storage.getItem("establishementToken");
    //const establishementToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOiI0Y2JlYmM3NC01NTQwLTQyOGMtODA2Zi1jOGRjY2Y0NzJjMmEiLCJuYW1lIjoiU2FnaHJvdW4iLCJyb2xlIjoiIiwibmJmIjoxNzExMzcyMzI2LCJleHAiOjE3MTE5NzcxMjYsImlhdCI6MTcxMTM3MjMyNiwiaXNzIjoiU2VjdXJlQXBpIiwiYXVkIjoiU2VjdXJlQXBpVXNlciJ9.feb1bmyHVkaohdv_nmTR2AijcHtB6HhRL4WWNe5kRyc";
    config.headers = {
      ...config.headers,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Credentials": "true",

    };

    if (establishementToken) {
      config.headers = {
        ...config.headers,
        "Content-Type": "application/json",
        Authorization: `bearer ${establishementToken}`,
      };
    } else {
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);


export const catalogueApi = {
  getCategories: (
    urlAzure: string
  ) => {
    const baseUrlCatalog = localStorage.getItem("api_catalogue");
    return axiosInstance.get(
      `${baseUrlCatalog}/Category/GetCategoriesByIdPosAndTypeMachineId?UrlAzure=${urlAzure}&MachineId=${MachineId}&TypeMachine=${TypeMachine}`
    );
  },

  getCategoryDetails: (
    categoryId: number,
    urlAzure: string
  ) => {
    const baseUrlCatalog = localStorage.getItem("api_catalogue");
    const url = `${baseUrlCatalog}/Category/GetCategoryDetailsByCategoryIdAndTypeMachine?CategoryId=${categoryId}&UrlAzure=${urlAzure}&MachineId=${MachineId}&TypeMachine=${TypeMachine}`;
    return axiosInstance.get(url);
  },

  GetSubMenu: (
    categoryId: number,
    menuId: string,
    urlAzure: string
  ) => {
    const baseUrlCatalog = localStorage.getItem("api_catalogue");
    const url = `${baseUrlCatalog}/Menu/GetSubMenuByCategoryIdMenuIdAndTypeMachine?CategoryId=${categoryId}&MenuId=${menuId}&UrlAzure=${urlAzure}&MachineId=${MachineId}&TypeMachine=${TypeMachine}`;
    return axiosInstance.get(url);
  },

  GetLevelsByMenuId: (
    categoryId: string | undefined,
    menuId: number,
    urlAzure: string
  ) => {
    const baseUrlCatalog = localStorage.getItem("api_catalogue");
    const url = `${baseUrlCatalog}/Menu/GetMenuDetailsByCategoryIdMenuIdAndTypeMachine?CategoryId=${categoryId}&MenuId=${menuId}&UrlAzure=${urlAzure}&MachineId=${MachineId}&TypeMachine=${TypeMachine}`;
    return axiosInstance.get(url);
  },

  GetArticleComposition: (artileId: number, isLevelArticle: boolean) => {
    const baseUrlCatalog = localStorage.getItem("api_catalogue");
    const url = `${baseUrlCatalog}/Article/GetArticleCompositionByArticleId?ArticleId=${artileId}&IsLevelArticle=${isLevelArticle}`;
    return axiosInstance.get(url);
  },

  GetArticleById: (artileId: number, categoryId: number) => {
    const baseUrlCatalog = localStorage.getItem("api_catalogue");
    const url = `${baseUrlCatalog}/Article/GetArticleByIdForFioEat?ArticleId=${artileId}&CategoryId=${categoryId}`;
    return axiosInstance.get(url);
  },

  GetMenuById: (artileId: number) => {
    const baseUrlCatalog = localStorage.getItem("api_catalogue");
    const url = `${baseUrlCatalog}/Menu/GetMenuByIdForFIOEat?MenuId=${artileId}`;
    return axiosInstance.get(url);
  },

  SavedOrder: (orderPayload: OrderPayload) => {
    const url = `http://showroom1.frenchinnov.fr:150/api/applis/Command/AnticipateExternCommand`;
    return axiosInstance.post(url, orderPayload);
  },

  getEstablishementToken: (urlAzure: string) => {
    const baseUrlCatalog = localStorage.getItem("api_catalogue");
    const url =`${baseUrlCatalog}/v1/getEstablishementToken?establishementUrl=${urlAzure}`
    return axiosInstance.get(url);
  },

  getClickAndCollectGraphicChart: (companyId: string) => {
    const baseUrlCatalog = localStorage.getItem("api_catalogue");
    const url =`${baseUrlCatalog}/ClickAndCollectGraphicChart/GetClickAndCollectGraphicChart?CompanyId=${companyId}`
    return axiosInstance.get(url);
  },

};  

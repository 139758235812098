import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { languageData } from "../../../../i18n/index";
import CloseIcon from "../../../../images/CloseIcon_btn.svg";
import EmptyCart from "../../../../images/empty-cartIcon.svg";
import RestaurantIcon from "../../../../images/RestaurantIcon.svg";
import PanierIcon from "../../../../images/shopping-bag-01.svg";
import { Cart, RestaurantCart } from "../../../../interfaces/Catalogue";
import { shopApi } from "../../../../services/apiGate";
import PanierList from "./PanierList/PanierList";
import CartData from "../CartModal/CartData/CartData";
import "./PanierModal.scss";

const PanierModal = (props: any) => {
  const navigate = useNavigate();
  const [show_panier, setShowPanier] = useState(false);
  const [PanierVide, SetPanierVide] = useState(false);
  const [currentCart, setCart] = useState<RestaurantCart>();
  const [ShowCommandDetails, setShowCommandDetails] = useState(false);
  const [ShowRestaurantError, setShowRestaurantError] = useState(false);
  const [blockBody, setblockBody] = useState(false);
  const savedCategories = useSelector(
    (state: { cart: Cart }) => state.cart.savedCategories
  );

  const savedCategoriesArray: RestaurantCart[] = Object.values(savedCategories);

  useEffect(() => {
    if (blockBody) {
      document.querySelector("body")?.classList.add("disable-scroll");
    } else {
      document.querySelector("body")?.classList.remove("disable-scroll");
    }
  }, [blockBody]);

  const handelBodyScroll = () => {
    setblockBody((prevblockBody) => !prevblockBody);
  };

  const showRestaurantCart = (restaurantCart: RestaurantCart) => {
    setblockBody(true);
    document.querySelector(".panier_box")?.classList.remove("showBox");
    setShowCommandDetails(!ShowCommandDetails);
    setCart(restaurantCart);
  };

  const checkRestaurantDisponibilty = async (id: number) => {
    const restaurantApi = await shopApi.getResturantById({
      id: id,
      diningMode: props.mode,
      adresse: {
        latitude: props.location?.lat,
        longitude: props.location?.lng,
      },
    });
    return (
      restaurantApi.data?.restaurant?.isEnabled &&
      restaurantApi.data?.restaurant?.isOpen
    );
  };

  const handleCommand = async (id: number) => {
    let disponible = await checkRestaurantDisponibilty(id);
    if (disponible) {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        localStorage.setItem("redirectedToAuth", "true");
        navigate("/Authentification");
      } else {
        localStorage.setItem("redirectedToAuth", "false");
        navigate("/paiement");
      }
    } else {
      setShowRestaurantError(!ShowRestaurantError);
    }
  };

  return (
    <div className={`panier_box ${show_panier ? "showBox" : ""}`}>
      <div
        className="panier-btn"
        onClick={() => {
          setShowPanier(!show_panier);
          handelBodyScroll();
        }}
      >
        <div className="panier-el">
          <img src={PanierIcon} alt="" />
          <p className="alias-panier">Panier</p>
          <b style={{ color: "white" }}>•</b>
        </div>
        <p className="products-count">{savedCategoriesArray.length}</p>
      </div>
      {/* cut here  */}
      <div className="panier-content-box ">
        <div
          onClick={() => {
            setShowPanier(!show_panier);
            handelBodyScroll();
          }}
          className="overlay-box"
        ></div>
        <div
          onClick={() => SetPanierVide(!PanierVide)}
          className={`panier-data ${savedCategoriesArray.length <= 0 ? "hide-el" : ""}`}
        >
          <PanierList
            restaurantCarts={savedCategoriesArray}
            showRestaurantCart={showRestaurantCart}
          />
        </div>
        <div
          onClick={() => SetPanierVide(!PanierVide)}
          className={`empty-panier ${savedCategoriesArray.length === 0 ? " " : "hide-el"}`}
        >
          <div className="panier-wrapper">
            <img src={EmptyCart} alt="EmptyCart" />
            <p>Aucun Article ajouté</p>
            <span>Ajoutez des articles, ils seront affichés ici.</span>
          </div>
        </div>
      </div>

      <div
        className={`CommandInfo CartNav ${ShowCommandDetails ? "showCommandDetails" : ""}`}
      >
        <div
          onClick={() => {
            setShowCommandDetails(!ShowCommandDetails);
            handelBodyScroll();
          }}
          className="overlay-box"
        ></div>

        {savedCategoriesArray.length > 0 && currentCart && (
          <CartData
            setShowCommandDetails={setShowCommandDetails}
            ShowCommandDetails={ShowCommandDetails}
            restaurantCart={currentCart}
            handleCommand={handleCommand}
          />
        )}
      </div>
      <div className={`restaurantError ${ShowRestaurantError ? "" : "hide"}`}>
        <div
          className="overlay-modal"
          onClick={() => setShowRestaurantError(!ShowRestaurantError)}
        ></div>
        <div className="modalContent">
          <div className="wrapper">
            <img
              className="close-btn"
              src={CloseIcon}
              alt=""
              onClick={() => {
                setShowRestaurantError(!ShowRestaurantError);
                handelBodyScroll();
              }}
            />
            <div>
              <p className="modalTitle">
                {languageData.fr.ModalRestaurantError.modalTitle}
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={RestaurantIcon} alt="" />
                <p className="modalDescription">
                  {languageData.fr.ModalRestaurantError.ModalDescription}
                </p>
              </div>
            </div>

            <button
              onClick={() => setShowRestaurantError(!ShowRestaurantError)}
            >
              {languageData.fr.ModalRestaurantError.ModalButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanierModal;

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ReplayIcon from "@mui/icons-material/Replay";
import parsePhoneNumberFromString from "libphonenumber-js";
import {
  ChangeEvent,
  ClipboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { NavAuth } from "../../components/Auth/NavAuth/NavAuth";
import withNavigateHook from "../../hooks/withNavigateHook";
import { languageData } from "../../i18n";
import {
  clearStoreItem,
  sendVerificationCode,
  updateLoading,
  updateTokens,
  updateUserAuth,
  verifyCode,
} from "../../store/actions/actions";
import "./AuthStyles.scss";
import CircularProgress from "@mui/material/CircularProgress";
import { COUNTRY_CODES } from "../../constants/constants";

const OptPage = (props: any) => {
  //externalized config
  const [timeLeft, setTimeLeft] = useState<number>(10);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [hasCodeError, setHasCodeError] = useState<boolean>(false);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    console.log("props.location.state.preferredMethod", props.location.state);
    if (props.location.state.prefferedMethod == 1) {
      setLoading(true);
      setOtp(["1", "5", "8", "9"]);
    } else {
      setLoading(false);
    }
  }, []);

  window.addEventListener("popstate", (event) => {
    props.navigate("/");
  });

  useEffect(() => {
    if (props.user === null || props.user.length === 0) {
      props.navigate("/Authentification");
    }
  }, []);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(0);
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  const handleGoback = () => {
    props.navigate("/Authentification");
    props.clearItemStore("verifyCodeSuccess");
    props.clearItemStore("sendVerificationCodeResponse");
    props.clearItemStore("resendVerificationCodeResponse");
    props.clearItemStore("authError");
  };

  useEffect(() => {
    if (otp?.length === 4 && !otp.includes("")) {
      // console.log("props.location?.state?.input", props.location?.state?.input);
      const input = props.location.state.prefferedMethod === 1 ? props.location?.state?.input : props.location?.state?.input.replace(COUNTRY_CODES.code, "");
      props.verifyCode({
        input:input ||
          (props.authGoogleSuccess
            ? props.authGoogleSuccess?.payloadWeb?.email
            : props.authAppleSuccess?.payload?.email),
        verificationCode: otp.join(""),
        validationStepToken:
          props.validationStepToken ||
          (props.authGoogleSuccess &&
            props.authGoogleSuccess.validationStepToken) ||
          (props.authAppleSuccess &&
            props.authAppleSuccess.validationStepToken),
      });
    }
  }, [otp]);

  useEffect(() => {
    if (props.authError && !props.sendVerificationCodeResponse) {
      setHasCodeError(true);
    }
  }, [props.authError, props.authRetries]);

  useEffect(() => {
    if (props.verifyCodeSuccess && otp[0] !== "") {
      if (props.verifyCodeSuccess?.codeValide === false) setHasCodeError(true);
      else if (props.verifyCodeSuccess?.codeValide === true) {
        let isNewUser = props.sendVerificationCodeResponse?.mode == 2;
        props.updateTokens(
          props.verifyCodeSuccess?.validationStepToken,
          props.verifyCodeSuccess?.validationSignupToken,
          props.verifyCodeSuccess?.token
        );

        props.clearItemStore("verifyCodeSuccess");
        props.clearItemStore("authError");
        props.navigate("../success", { state: isNewUser, replace: true });
      }
    }
  }, [props.verifyCodeSuccess, props.authRetries]);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    //call format controller
    //data format
    //API call
    //navigation next page otp
  };

  const handleResendCode = () => {
    setTimeLeft(5);
    props.sendVerificationCode(props.location?.state, true);
  };

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = parseInt(e.target.value);

    setHasCodeError(false);

    if (!isNaN(value)) {
      const newOtp = [...otp];

      newOtp[index] = value.toString();
      setOtp(newOtp);

      //Move focus to the next input field
      if (
        (value && inputRefs.current[index + 1]) ||
        (value === 0 && inputRefs.current[index + 1])
      ) {
        inputRefs.current[index + 1]?.focus();
      }
    } else {
      const newOtp = [...otp];

      newOtp[index] = "";
      setOtp(newOtp);

      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    const pasteData = e.clipboardData?.getData("text/plain").trim();
    const pastedOtp = pasteData?.match(/\d/g); // Extract only numeric values

    if (pastedOtp && pastedOtp.length === 4) {
      const newOtp = pastedOtp.slice(0, 4);

      setOtp(newOtp);

      // Update each input field with the pasted value
      newOtp.forEach((value, index) => {
        if (inputRefs.current[index]) {
          inputRefs.current[index]!.value = value;
        }
      });
    }
  };

  return (
    <div className="authPage">
      <NavAuth />
      <div style={{ maxWidth: "455px" }} className="content">
        {loading ? (
          <div className="loading-spinner">
            <CircularProgress />
          </div>
        ) : (
          <>
            <p className="tite-form">
              Saisissez le code à 4 chiffres envoyé{" "}
              {props.user?.type === "email"
                ? "à " + props.user?.email
                : "au " + (props.user?.phone ? props.user.phone : "")}
            </p>

            {/* <h3>Saisissez le code à 4 chiffres envoyé {props.user?.type === "email" ? "à " + props.user?.email : "au " + parsePhoneNumberFromString(props.user?.phone)?.formatInternational()}</h3> */}
            {/* <h3>
          Saisissez le code à 4 chiffres envoyé{" "}
          {props.user?.type === "email"
            ? "à " + props.user?.email
            : "au " +
              (props.user?.phone
                ? parsePhoneNumberFromString(
                    props.user.phone
                  )?.formatInternational()
                : "")}
        </h3> */}

            <div className="otpInputBlock">
              <input
                className={
                  otp[0] === "" ? "otpInput backgroundDash" : "otpInput"
                }
                placeholder="-"
                value={otp[0]}
                ref={(input) => (inputRefs.current[0] = input)}
                type="text"
                maxLength={1}
                max={9}
                min={0}
                onChange={(e) => handleChange(e, 0)}
                onPaste={handlePaste}
              />
              <input
                className={
                  otp[1] === "" ? "otpInput backgroundDash" : "otpInput"
                }
                placeholder="-"
                value={otp[1]}
                ref={(input) => (inputRefs.current[1] = input)}
                type="text"
                maxLength={1}
                max={9}
                min={0}
                onChange={(e) => handleChange(e, 1)}
                onPaste={handlePaste}
              />
              <input
                className={
                  otp[2] === "" ? "otpInput backgroundDash" : "otpInput"
                }
                placeholder="-"
                value={otp[2]}
                ref={(input) => (inputRefs.current[2] = input)}
                type="text"
                maxLength={1}
                max={9}
                min={0}
                onChange={(e) => handleChange(e, 2)}
                onPaste={handlePaste}
              />
              <input
                className={
                  otp[3] === "" ? "otpInput backgroundDash" : "otpInput"
                }
                placeholder="-"
                value={otp[3]}
                ref={(input) => (inputRefs.current[3] = input)}
                type="text"
                maxLength={1}
                max={9}
                min={0}
                onChange={(e) => handleChange(e, 3)}
                onPaste={handlePaste}
              />
            </div>

            {hasCodeError && (
              <div className="error-message">
                <p>{languageData.fr.auth["login.otp.input.error"]}</p>
              </div>
            )}
            <div className="otpFooter">
              <div id="resendCodeDiv" style={{ width: "100%" }}>
                <span>Code non réçu ?</span>
                <button
                  onClick={() => handleResendCode()}
                  id="resendCodeButton"
                  className={timeLeft > 0 ? "disabled" : ""}
                  disabled={timeLeft > 0 ? true : false}
                >
                  <b>
                    {" "}
                    <span style={{ fontWeight: "500" }}>
                      {timeLeft === 0
                        ? ""
                        : timeLeft < 10
                          ? " (0:0" + timeLeft + ") "
                          : " (0:" + timeLeft + ") "}{" "}
                      Renvoyer code
                    </span>{" "}
                  </b>
                  <ReplayIcon />
                </button>
              </div>

              <div id="navButtonGroup">
                <button id="back" onClick={() => props.navigate("/")}>
                  {" "}
                  <NavigateBeforeIcon />
                </button>
                <button
                  className="continue"
                  id="next"
                  onClick={(e) => handleSubmit(e)}
                >
                  {" "}
                  {"Suivant"} <NavigateNextIcon />{" "}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
    resendVerificationCodeResponse: state.auth.resendVerificationCodeResponse,
    verifyCodeSuccess: state.auth.verifyCodeSuccess,
    sendVerificationCodeResponse: state.auth.sendVerificationCodeResponse,
    authRetries: state.auth.authRetries,
    authError: state.auth.authError,
    validationStepToken: state.auth.validationStepToken,
    validationSignupToken: state.auth.validationSignupToken,
    authGoogleSuccess: state.auth.authGoogleSuccess,
    authAppleSuccess: state.auth.authAppleSuccess,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateUserInfo: (userInfo: any) => dispatch(updateUserAuth(userInfo)),
    sendVerificationCode: (
      body: serviceAuthModels.SendVerificationCodeBody,
      resend: boolean
    ) => dispatch(sendVerificationCode(body, resend)),
    updateLoading: (value: boolean) => dispatch(updateLoading(value)),
    verifyCode: (body: serviceAuthModels.verifyCodeBody) =>
      dispatch(verifyCode(body)),
    clearItemStore: (item: string) => dispatch(clearStoreItem(item)),
    updateTokens: (verifToken: string, signupToken: string, token: any) =>
      dispatch(updateTokens(verifToken, signupToken, token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(OptPage));

import * as React from "react";

const MinusIcon = ({
  color = "#E05F03", // default color
  ...props
}: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { color?: string }) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} y={0.5} width={23} height={23} rx={11.5} fill="white" />
    <rect x={0.5} y={0.5} width={23} height={23} rx={11.5} stroke="#D0D5DD" />
    <path
      d="M17.25 13H12.75V17.5H11.25V13H6.75V11.5H11.25V7H12.75V11.5H17.25V13Z"
      fill={color}
    />
  </svg>
);

export default MinusIcon;

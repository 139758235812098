import { all, takeEvery } from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { editMyProfileSaga, getMyProfileSaga, startSendVerificationCodeSaga, startSignUpSaga, startVerifyCodeSaga, verifyAppleTokenSaga, verifyGoogleTokenSaga } from './authSaga';
import *as actionTypes from '../actions/actionTypes'
import { addShopToFavoriteSaga, getFavoritesFilredShopsSaga, getFilredShopsSaga } from './shopSaga';
import { getAllCategoriesSaga, getShopCategoryListSaga } from './categoryShop';
import { getShopMenuSaga } from './menuSaga';
import { getAllOrdersListSaga, getOrderDetailsSaga } from './orderSaga';
import { confirmPaymentSaga, createPaymentSaga } from './paymentSaga';
import categoriesSaga from '../V2/categories/categoriesSagas';

es6promise.polyfill();

function* rootSaga() {
  yield all([
    takeEvery(actionTypes.SEND_VERIFICATION_CODE, startSendVerificationCodeSaga),
    takeEvery(actionTypes.VERIFY_CODE, startVerifyCodeSaga),
    takeEvery(actionTypes.SINGUP, startSignUpSaga),
    takeEvery(actionTypes.GET_FILTRED_SHOP, getFilredShopsSaga),
    takeEvery(actionTypes.GET_CATEGORIES_LIST, getAllCategoriesSaga),
    takeEvery(actionTypes.GET_CATEGORY_SHOP_LIST, getShopCategoryListSaga),
    takeEvery(actionTypes.GET_RESTAURANT_MENU, getShopMenuSaga),
    takeEvery(actionTypes.GET_ORDER_LIST, getAllOrdersListSaga),
    takeEvery(actionTypes.ADD_SHOP_TO_FAVORITE, addShopToFavoriteSaga),
    takeEvery(actionTypes.GET_FAVORITES_FILTRED_SHOP, getFavoritesFilredShopsSaga),
    takeEvery(actionTypes.GET_MY_PROFILE, getMyProfileSaga),
    takeEvery(actionTypes.EDIT_MY_PROFILE, editMyProfileSaga),
    takeEvery(actionTypes.CREATE_PAYMENT, createPaymentSaga),
    takeEvery(actionTypes.CONFIRM_PAYMENT, confirmPaymentSaga),
    takeEvery(actionTypes.VERIFY_GOOGLE, verifyGoogleTokenSaga),
    takeEvery(actionTypes.VERIFY_APPLE, verifyAppleTokenSaga),
    takeEvery(actionTypes.GET_ORDER_BY_ID, getOrderDetailsSaga),
    takeEvery(actionTypes.GET_ORDER_BY_ID, getOrderDetailsSaga),
    categoriesSaga()
  ]);
}

export default rootSaga;

import * as React from "react";

const RoundSearchIcon = ({
  color = "#E05F03", // default color
  ...props
}: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { color?: string }) => (
  <svg
    width={31}
    height={32}
    viewBox="0 0 31 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y={0.494141} width={31} height={31} rx={15.5} fill={color} />
    <path
      d="M22.3086 20.8574C22.5547 21.1309 22.5547 21.541 22.2812 21.7871L21.5156 22.5527C21.2695 22.8262 20.8594 22.8262 20.5859 22.5527L17.8789 19.8457C17.7422 19.709 17.6875 19.5449 17.6875 19.3809V18.916C16.7031 19.6816 15.5 20.1191 14.1875 20.1191C11.043 20.1191 8.5 17.5762 8.5 14.4316C8.5 11.3145 11.043 8.74414 14.1875 8.74414C17.3047 8.74414 19.875 11.3145 19.875 14.4316C19.875 15.7715 19.4102 16.9746 18.6719 17.9316H19.1094C19.2734 17.9316 19.4375 18.0137 19.5742 18.123L22.3086 20.8574ZM14.1875 17.9316C16.1016 17.9316 17.6875 16.373 17.6875 14.4316C17.6875 12.5176 16.1016 10.9316 14.1875 10.9316C12.2461 10.9316 10.6875 12.5176 10.6875 14.4316C10.6875 16.373 12.2461 17.9316 14.1875 17.9316Z"
      fill="white"
    />
  </svg>
);
export default RoundSearchIcon;

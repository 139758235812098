import CheckIcon from "@mui/icons-material/Check";
import CircleIcon from "@mui/icons-material/Circle";
import { FormControl, TextField } from "@mui/material";
import axios from "axios";
import { passwordStrength } from "check-password-strength";
import { FormEvent, useEffect, useState } from "react";
import { connect } from "react-redux";
import Eye from "../../assets/auth-icons/eye.svg";
import { NavAuth } from "../../components/Auth/NavAuth/NavAuth";
import withNavigateHook from "../../hooks/withNavigateHook";
import { languageData } from "../../i18n";
import {
  clearStoreItem,
  getmyprofile,
  sendVerificationCode,
  updateLoading,
  updateTokens,
  updateUserAuth,
  verifyCode,
} from "../../store/actions/actions";
import "./AuthStyles.scss";

const ChangePasswordPage = (props: any) => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [errorPassword, setErrorPassword] = useState<string>("");
  const [ShowPw01, SetPassword01] = useState(false);
  const [ShowPw02, SetPassword02] = useState(false);
  const [strengthPassword, setStrengthPassword] = useState<
    string | undefined
  >();

  // validated states
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [specialValidated, setSpecialValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);

  const otpToSend = localStorage.getItem("otpToSend");
  let code = otpToSend;
  let color;

  window.addEventListener("popstate", (event) => {
    localStorage.removeItem("otpToSend");
    props.navigate("/");
  });

  useEffect(() => {
    if (props.user === null || props.user.length === 0) {
      localStorage.removeItem("otpToSend");
      props.navigate("/Authentification");
    }
  }, []);

  const handleChange = (value: any) => {
    const lower = new RegExp("(?=.*[a-z])");
    const upper = new RegExp("(?=.*[A-Z])");
    const number = new RegExp("(?=.*[0-9])");
    const special = new RegExp("(?=.*[!@#$%^&*])");
    const length = new RegExp("(?=.{8,})");
    if (lower.test(value)) {
      setLowerValidated(true);
    } else {
      setLowerValidated(false);
    }
    if (upper.test(value)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }
    if (number.test(value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }
    if (special.test(value)) {
      setSpecialValidated(true);
    } else {
      setSpecialValidated(false);
    }
    if (length.test(value)) {
      setLengthValidated(true);
    } else {
      setLengthValidated(false);
    }

    setPassword(value);
    const strength = passwordStrength(value).value;
    setStrengthPassword(strength);
  };

  const handleSendCodePassword = async (
    userData: any,
    password: string,
    code: string | null
  ) => {
    try {
      const baseUrl = localStorage.getItem("api_baseUrl");
      const response = await axios.post(
        baseUrl + "/Account/RéinitialiserMDP",
        { input: userData, mdp: password, code: code },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (password === "") {
      setErrorPassword(languageData.fr.auth["login.header.pass.obligatoire"]);
      return;
    }
    if (
      !lowerValidated ||
      !upperValidated ||
      !numberValidated ||
      !specialValidated ||
      !lengthValidated
    ) {
      setErrorPassword(
        "Le mot de passe doit contenir au moins une lettre minuscule, une lettre majuscule, un chiffre, un caractère spécial et comporter au moins 8 caractères."
      );
      return;
    }
    if (confirmPassword !== password) {
      setErrorPassword(languageData.fr.auth["login.header.pass.confirm"]);
      return;
    }
    try {
      // Check if props.user exists and either props.user.phone or props.user.email is defined
      const userData = props.user && (props.user?.phone || props.user?.email);
      if (userData) {
        await handleSendCodePassword(userData, password, code);
        props.navigate("/Authentification/");
      } else {
        console.error("Error: User data (phone or email) is undefined");
        // Handle the case where user data is undefined
      }
    } catch (error) {
      console.error("Error sending code:", error);
      // Handle error
    }
  };

  switch (strengthPassword) {
    case "Too weak":
      color = "red";
      break;
    case "Weak":
      color = "orange";
      break;
    case "Medium":
      color = "yellow";
      break;
    case "Strong":
      color = "green";
      break;
    default:
      color = "inherit";
  }

  return (
    <div className="authPage">
      <NavAuth />
      <div className="content reset-pw">
        <form onSubmit={handleSubmit}>
          <p className="tite-form">
            Réinitialisation de <br /> mot de passe
          </p>
          <FormControl fullWidth className="InfoForm" variant="standard">
            <small
              style={{ textAlign: "left", width: "100%", paddingBottom: "5px" }}
            >
              Veuillez saisir votre mot de passe*
            </small>

            <div className="pw-field">
              <TextField
                type={`${ShowPw01 ? "text" : "password"}`}
                onChange={(e) => handleChange(e.target.value)}
                fullWidth
                className="personalinput"
                variant="standard"
                placeholder="***********"
              ></TextField>
              <img
                onClick={() => SetPassword01(!ShowPw01)}
                className="show-pw"
                src={Eye}
                alt=""
              />
            </div>
          </FormControl>
          <FormControl fullWidth className="InfoForm" variant="standard">
            <small style={{ textAlign: "left", width: "100%" }}>
              Confirmer Mot de passe *
            </small>
            <div className="pw-field">
              <TextField
                type={`${ShowPw02 ? "text" : "password"}`}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                className="personalinput"
                variant="standard"
                placeholder="***********"
              ></TextField>
              <img
                onClick={() => SetPassword02(!ShowPw02)}
                className="show-pw"
                src={Eye}
                alt=""
              />
            </div>

            <p className="error-msg">{errorPassword ? errorPassword : ""}</p>
          </FormControl>
          <span>
            Niveau de sécurité :{" "}
            {password === "" ? (
              ""
            ) : (
              <div style={{ color: color }}>{strengthPassword}</div>
            )}
          </span>
          <main className="tracker-box">
            <div className={lowerValidated ? "validated" : "not-validated"}>
              {lowerValidated ? (
                <span className="list-icon green">
                  <CheckIcon style={{ fontSize: "20px" }} />
                </span>
              ) : (
                <span className="list-icon">
                  <CircleIcon />
                </span>
              )}

              <span className="label-verification">minuscules</span>
            </div>
            <div className={upperValidated ? "validated" : "not-validated"}>
              {upperValidated ? (
                <span className="list-icon green">
                  <CheckIcon style={{ fontSize: "20px" }} />
                </span>
              ) : (
                <span className="list-icon">
                  <CircleIcon />
                </span>
              )}
              <span className="label-verification">Majuscules</span>
            </div>
            <div className={numberValidated ? "validated" : "not-validated"}>
              {numberValidated ? (
                <span className="list-icon green">
                  <CheckIcon style={{ fontSize: "20px" }} />
                </span>
              ) : (
                <span className="list-icon">
                  <CircleIcon />
                </span>
              )}
              <span className="label-verification">Nombre numérique</span>
            </div>
            <div className={specialValidated ? "validated" : "not-validated"}>
              {specialValidated ? (
                <span className="list-icon green">
                  <CheckIcon style={{ fontSize: "20px" }} />
                </span>
              ) : (
                <span className="list-icon">
                  <CircleIcon />
                </span>
              )}
              <span className="label-verification">Symbole (#&$)</span>
            </div>
            <div className={lengthValidated ? "validated" : "not-validated"}>
              {lengthValidated ? (
                <span className="list-icon green">
                  <CheckIcon style={{ fontSize: "20px" }} />
                </span>
              ) : (
                <span className="list-icon">
                  <CircleIcon />
                </span>
              )}
              <span className="label-verification">Minimum 8 caractères</span>
            </div>
          </main>

          <div>
            <button className="submit" type="submit">
              Sauvegarder
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
    resendVerificationCodeResponse: state.auth.resendVerificationCodeResponse,
    verifyCodeSuccess: state.auth.verifyCodeSuccess,
    sendVerificationCodeResponse: state.auth.sendVerificationCodeResponse,
    authRetries: state.auth.authRetries,
    authError: state.auth.authError,
    validationStepToken: state.auth.validationStepToken,
    validationSignupToken: state.auth.validationSignupToken,
    userRedirectAfterLoginTo: state.auth.userRedirectAfterLoginTo,
    userInfo: state.auth.userInfo,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateUserInfo: (userInfo: any) => dispatch(updateUserAuth(userInfo)),
    sendVerificationCode: (
      body: serviceAuthModels.SendVerificationCodeBody,
      resend: boolean
    ) => dispatch(sendVerificationCode(body, resend)),
    updateLoading: (value: boolean) => dispatch(updateLoading(value)),
    verifyCode: (body: serviceAuthModels.verifyCodeBody) =>
      dispatch(verifyCode(body)),
    clearItemStore: (item: string) => dispatch(clearStoreItem(item)),
    updateTokens: (verifToken: string, signupToken: string, token: any) =>
      dispatch(updateTokens(verifToken, signupToken, token)),
    getmyprofile: () => dispatch(getmyprofile()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(ChangePasswordPage));

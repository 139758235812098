import React, { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

interface LazyLoadComponentProps {
  alt?: string;
  height?: string;
  ClassName?: string;
  src: string ;
  width?: string;
  effect?: "blur" | "opacity" | undefined;
  style?: React.CSSProperties;
}

const ImageLazyLoader: React.FC<LazyLoadComponentProps> = ({
  alt = "",
  height,
  src,
  width,
  effect = "blur",
  ClassName,
  style,
}) => {
  const [isInView, setIsInView] = useState(false);
  const imgRef = useRef(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!timeoutRef.current) {
              timeoutRef.current = setTimeout(() => {
                setIsInView(true); // Load the image after 100 milliseconds
              }, 100); // 100 milliseconds delay
            }
          } else {
            // If the image is no longer in view, clear the timeout
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
              timeoutRef.current = null;
            }
          }
        });
      },
      {
        rootMargin: "0px",
        threshold: 0.1, // Trigger the observer when 10% of the image is visible
      }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current); // Start observing the image element
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current); // Clean up the observer when the component unmounts
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // Clean up timeout on unmount
      }
    };
  }, []);

  const preventContextMenu = (e: any) => {
    e.preventDefault();
  };

  return (
    <div className="img-container" ref={imgRef} onContextMenu={preventContextMenu} style={style}>
      <LazyLoadImage
        alt={alt}
        height={height}
        src={isInView ? src: ""}
        width={width}
        effect={effect}
        className={ClassName}
      />
    </div>
  );
};

export default ImageLazyLoader;

import { CSSProperties } from "react";
import EditIcon from "../images/EditIcon";
import FilledLocationIcon from "../images/FilledLocationIcon";
import LocationIcon from "../images/LocationIcon";
import MinusIcon from "../images/MinusIcon";
import PlusIcon from "../images/PlusIcon";
import RightArrowIcon from "../images/RightArrowIcon";
import RoundSearchIcon from "../images/RoundSearchIcon";
import SearchIcon from "../images/SearchIcon";
import TrashIcon from "../images/TrashIcon";
import UserProfilIcon from "../images/UserProfilIcon";
import { colors } from "./colors";

export enum IconType {
  USER = "user",
  SEARCH = "search",
  ROUND_SEARCH = "round-search",
  EDIT = "edit",
  LOCATION = "location",
  LOCATION_ON_ROUND = "location-on-round",
  MINUSBOX = "minusbox",
  RIGHT_ARROW = "right-arrow",
  NO_ELEMENT = "no-element",
  PLUS = "plus",
  MINUS = "minus",
  TRASH = "trash",
}

interface IconProps {
  color?: string; 
  style?: CSSProperties; 
  [key: string]: any;
}

export const getIcon = (type: IconType, props?: IconProps) => {
  const color = props?.color || colors.primary; 

  switch (type) {
    case IconType.EDIT:
      return <EditIcon color={color} {...props} />;

    case IconType.LOCATION_ON_ROUND:
      return <FilledLocationIcon color={color} {...props} />;

    case IconType.LOCATION:
      return <LocationIcon color={color} />;

    case IconType.SEARCH:
      return <SearchIcon color={color} {...props} />;

    case IconType.ROUND_SEARCH:
      return <RoundSearchIcon color={color} {...props} />;

    case IconType.PLUS:
      return <PlusIcon color={color} {...props} />;

    case IconType.MINUS:
      return <MinusIcon color={color} {...props} />;

    case IconType.TRASH:
      return <TrashIcon color={color} {...props} />;

    case IconType.USER:
      return <UserProfilIcon color={color} {...props} />;
      
    case IconType.RIGHT_ARROW:
      return <RightArrowIcon color={color} {...props} />;

    default:
      return <div>No icon found</div>;
  }
};

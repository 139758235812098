import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MenuList from "../../components/RestaurantMenuPage/MenuList/MenuList";
import Navbar from "../../components/shared/AppNavbar/AppNavbar";
import { ApiResponse } from "../../interfaces/ApiResponse";
import { CategoryItem, Restaurant } from "../../interfaces/Catalogue";
import { catalogueApi } from "../../services/catalogueApi";
import "./SubMenuPage.scss";
import { shopApi } from "../../services/apiGate";

const SubMenuPage = () => {
  const menuPage = true;
  const navigate = useNavigate();
  const location = useLocation();
  const { restaurantId, categoryId, menuId, typeOrder, menuName } = useParams();
  const [subMenus, setSubMenus] = useState<CategoryItem[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [restaurant, setRestaurant] = useState<Restaurant>();

  useEffect(() => {
    const fetchRestaurant = async () => {
      try {
        const response = await shopApi.getResturantById({
          id: Number(restaurantId),
          diningMode: Number(typeOrder),
          adresse: {
            latitude: 0,
            longitude: 0,
          },
        });
        if (menuId && categoryId) {
          fetchSubMenus(categoryId, menuId, response.data?.restaurant.azureUrl);
        }
        setRestaurant(response.data?.restaurant);
      } catch (error) {
        console.error("Error fetching restaurant:", error);
      }
    };

    if (restaurantId) {
      fetchRestaurant();
    }
  }, [restaurantId]);

  const fetchSubMenus = async (
    categoryId: string,
    menuId: string,
    azureUrl: string
  ) => {
    try {
      setLoading(true);
      const response: { data: ApiResponse<CategoryItem[]> } =
        await catalogueApi.GetSubMenu(
          Number(categoryId),
          String(menuId),
          azureUrl
        );
      const result = response.data.data.result;
      setSubMenus(result);
    } catch (error: any) {
      console.error("🚀 ~ fetchCategoryDetails ~ error:", error);
      // const { response } = error;
      // const errorCodeFI = response?.data?.failureResponse?.codeFI?.code;
      // const errorCodeReq = response?.status;
      // setError(errorCodeFI || errorCodeReq);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="subMenuPage">
      <Navbar menuPage={menuPage} />
      <div className="BodyPage">
        <div className="submenu-header">
          <div className="navigation">
            <ArrowBackIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/restaurant/${restaurantId}`);
              }}
            />

            <div>
              <div className="submenu-details">
                <p className="submenu-name">{menuName}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <MenuList
            loading={loading}
            categoryItems={subMenus}
            categoryId={Number(categoryId)}
            restaurantLogo={restaurant?.imageUrl || ""}
            isSubMenu={true}
            restaurantId={restaurant?.id}
            typeOrder={Number(typeOrder)}
          />
        </div>
      </div>
    </div>
  );
};

export default SubMenuPage;

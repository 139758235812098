export const isColorDark = (color:string) => {
  // Convert HEX to RGB
  let r, g, b;
  if (color.startsWith("#")) {
    const hex = color.replace("#", "");
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  } else {
    // For other formats (e.g., rgb())
    [r, g, b] = color
      .replace(/[^\d,]/g, "")
      .split(",")
      .map(Number);
  }

  // Calculate luminance
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
  return luminance < 128; // Threshold for dark
};
import moment from "moment";
import { RestaurantCart } from "../../../../../interfaces/Catalogue";
import { findCurrentShift } from "../../../../../utils/shopSubtittleFormat";

interface CartHeaderProps {
  restaurantCart: RestaurantCart;
}

export default function CartHeader(props: CartHeaderProps) {
  const { restaurantCart } = props;
  const currentShift = findCurrentShift(restaurantCart?.restaurant);

  return (
    <div className="CartHeader">
      {props?.restaurantCart?.categories?.length === 0 ? (
        "No restaurantCart"
      ) : (
        <div>
          <div className="wrapper">
            <p className="restaurantName">
              {restaurantCart?.restaurant?.nom}
            </p>
            <p className="restaurant-status">
              {currentShift?.isCurrentShift ? "Fermé" : "Ouvre"} à{" "}
              {currentShift?.isCurrentShift
                ? moment(currentShift?.close, "HH:mm").format("HH:mm")
                : moment(currentShift?.open, "HH:mm").format("HH:mm")}{" "}
              •
            </p>
            <div className="col-wrapper">
              <p className="restaurantAdresse">
                {restaurantCart?.mode == 0 ? "A Emporter" : "Sur Place"} :
                {restaurantCart?.restaurant?.address}
              </p>
              <p className="articlesSlot">
                {restaurantCart?.categories?.length} article
                {restaurantCart?.categories?.length > 1 ? "(s)" : ""}
              </p>
            </div>
          </div>
          <div className="seperator-cart"></div>
        </div>
      )}
    </div>
  );
}

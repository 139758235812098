import * as React from "react";

const UserProfilIcon = ({
  color = "#E05F03", // default color
  ...props
}: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { color?: string }) => (
  <svg
    width={41}
    height={40}
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={1.5} y={1} width={38} height={38} rx={19} fill="white" />
    <rect
      x={1.5}
      y={1}
      width={38}
      height={38}
      rx={19}
      stroke={color}
      strokeWidth={2}
    />
    <path
      d="M28.5 29C28.5 27.6044 28.5 26.9067 28.3278 26.3389C27.94 25.0605 26.9395 24.06 25.6611 23.6722C25.0933 23.5 24.3956 23.5 23 23.5H18C16.6044 23.5 15.9067 23.5 15.3389 23.6722C14.0605 24.06 13.06 25.0605 12.6722 26.3389C12.5 26.9067 12.5 27.6044 12.5 29M25 15.5C25 17.9853 22.9853 20 20.5 20C18.0147 20 16 17.9853 16 15.5C16 13.0147 18.0147 11 20.5 11C22.9853 11 25 13.0147 25 15.5Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default UserProfilIcon;

import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Typography } from "@mui/joy";
import {
  Button,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { Fragment, useState } from "react";
import {
  GroupedArticleByLevel,
  Ingredient,
  MenuItem,
  SelectedIngredient,
  SelectionMode,
} from "../../../../../../interfaces/Catalogue";
import {
  findPriceByTypeOrder,
  getPriceByTypeOrderForComposition,
} from "../../../../../../utils";
import IngredientModal from "../Modals/IngredientModal/IngredientModal";
import "./MonoSelectionList.scss";

interface LevelUniSelectionProps {
  typeOrder: number;
  level: GroupedArticleByLevel;
  updateSelectedArticles: (
    selecteMenuItem: MenuItem,
    selectedIngredients: SelectedIngredient[],
    eliminatedIngredients: SelectedIngredient[],
    mode: SelectionMode
  ) => void;
}

const MonoSelectionList = (props: LevelUniSelectionProps) => {
  const { level, typeOrder, updateSelectedArticles } = props;
  const [checkedItem, setCheckedItem] = useState<MenuItem>();
  const [ingredientModalState, setIngredientModalState] = useState<{
    isOpen: boolean;
    activeIndex: number | null;
  }>({ isOpen: false, activeIndex: null });
  const [ingredientSelections, setIngredientSelections] = useState<
    SelectedIngredient[]
  >([]);

  const handleOpenIngredientModal = (id: number) => {
    setIngredientModalState({ isOpen: true, activeIndex: id });
  };

  const handleCloseIngredientModal = () => {
    setIngredientModalState({ isOpen: false, activeIndex: null });
  };

  const handleSelect = (menuItem: MenuItem) => {
    menuItem.quantity = 1;
    setCheckedItem(menuItem);
    // if (menuItem?.articleComposition) {

    const initialSelectedIngredients = menuItem?.articleComposition
      ?.filter((ingredient: Ingredient) => ingredient?.inclus)
      ?.map((ingredient: Ingredient) => ({
        id: ingredient?.id,
        ingredient,
        quantity: 1,
      }));

    setIngredientSelections(initialSelectedIngredients);
    // }
    updateSelectedArticles(
      menuItem,
      initialSelectedIngredients || [],
      [],
      SelectionMode.SINGLE
    );
  };

  const updateIngredientSelections = (
    selections: SelectedIngredient[],
    eliminatedIngredients: SelectedIngredient[],
    menuItem: MenuItem
  ) => {
    menuItem.quantity = 1;
    setCheckedItem(menuItem);
    setIngredientSelections(selections);
    updateSelectedArticles(menuItem, selections, eliminatedIngredients, SelectionMode.SINGLE);
  };

  const menuItemsByOrderType = level?.menuItems?.filter((menuItem) =>
    menuItem?.typeOrderPrices?.some((item) => item?.type === typeOrder)
  );

  return (
    <RadioGroup value={level?.name}>
      {menuItemsByOrderType?.map((menuItem: MenuItem, index: number) => {
        const price =
          findPriceByTypeOrder(menuItem?.typeOrderPrices, typeOrder) || 0;
        return (
          <div>
            <Fragment>
              <div
                className="componentContainer column"
                style={{ alignItems: "center" }}
              >
                <div className="componentRow full-width" style={{paddingRight:12}}>
                  <FormControlLabel
                    value={menuItem?.name}
                    checked={menuItem?.name == checkedItem?.name}
                    control={
                      menuItem.isOutOfStock ? (
                        <span className="epuise epuise-radio">Épuisé</span>
                      ) : (
                        <Radio
                          className={`radioIcon ${menuItem.isOutOfStock ? 'disabledRadio' : ''}`}
                        size="small"
                        disabled={menuItem.isOutOfStock}
                        />
                      )
                    }
                    label={
                      <div>
                        <Typography
                          style={{
                            fontFamily: "Poppins,sans-serif",
                            fontSize: "12px",
                            fontWeight: 600,
                            color: `${menuItem.isOutOfStock ? "#999999" : "#1D2939"}`,
                          }}
                        >
                          {menuItem.name.toLowerCase()}
                        </Typography>
                        {menuItem.typeOrderPrices ? (
                          <Typography
                            style={{
                              fontSize: "12px",
                              fontWeight: 500,
                              color: "#475467",
                            }}
                          >
                            {price > 0 && `+${price} €`}
                          </Typography>
                        ) : null}
                      </div>
                    }
                    labelPlacement="start"
                    className=""
                    style={{
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                    onChange={(e: any) => {
                      handleSelect(menuItem);
                    }}
                  />

                  {menuItem?.nbrComposition > 0 && (
                    <IconButton
                      onClick={() => {
                        !menuItem.isOutOfStock &&
                          handleOpenIngredientModal(menuItem.id);
                      }}
                    >
                      <div
                        className={`btnArrow ${menuItem.isOutOfStock ? "outOfStock" : ""}`}
                      >
                        <ChevronRightRoundedIcon />
                      </div>
                    </IconButton>
                  )}
                </div>

                {menuItem?.nbrComposition > 0 &&
                  ingredientSelections?.length > 0 && (
                    <Collapse
                      in={checkedItem?.name === menuItem?.name}
                      className="all-center tableComposition"
                      style={{ width: "70%" }}
                    >
                      <Stack
                        className="compositionPreview full-width"
                        spacing={0.5}
                        direction={"column"}
                      >
                        <Stack direction={"column"} spacing={0.7}>
                          {ingredientSelections?.map(
                            (
                              selectedIngredient: SelectedIngredient,
                              i: number
                            ) => {
                              const { ingredient, quantity } =
                                selectedIngredient;

                              const price = getPriceByTypeOrderForComposition(
                                typeOrder,
                                ingredient
                              );
                              return (
                                <p key={i} className="row tableItem">
                                  {quantity > 1 && quantity}{" "}
                                  {ingredient?.name.toLowerCase()}{" "}
                                  {price > 0 && ` +${price} €`}
                                </p>
                              );
                            }
                          )}
                        </Stack>

                        <Divider />
                        <Button
                          onClick={() => {
                            handleOpenIngredientModal(menuItem?.id);
                          }}
                          variant="text"
                          size="small"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <small style={{ color: "#344054" }}>
                            Modifier votre sélection{" "}
                          </small>
                          <ChevronRightRoundedIcon
                            style={{ fill: "#344054" }}
                          />
                        </Button>
                      </Stack>
                    </Collapse>
                  )}
              </div>
            </Fragment>

            {ingredientModalState?.isOpen &&
              ingredientModalState?.activeIndex === menuItem?.id && (
                <IngredientModal
                  isOpen={ingredientModalState?.isOpen}
                  onClose={handleCloseIngredientModal}
                  updateIngredientSelections={updateIngredientSelections}
                  menuItem={menuItem}
                  compositionMaxSelection={menuItem?.compositionMaxSelection}
                  ingredients={menuItem?.articleComposition}
                  typeOrder={typeOrder}
                  ingredientSelections={ingredientSelections}
                  isCheckedItem={checkedItem?.id === menuItem?.id}
                />
              )}
          </div>
        );
      })}
    </RadioGroup>
  );
};

export default MonoSelectionList;

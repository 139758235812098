import * as React from "react";
const EditIcon = ({
  color = "#E05F03", // default color
  ...props
}: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { color?: string }) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={0.5}
      width={24}
      height={24}
      rx={6.125}
      fill={color}
      fillOpacity={0.2}
    />
    <path
      d="M12.5 16.3336H17.375M7.625 16.3336H8.53204C8.79702 16.3336 8.9295 16.3336 9.05418 16.3037C9.16472 16.2771 9.27039 16.2334 9.36732 16.174C9.47665 16.107 9.57033 16.0133 9.7577 15.8259L16.5625 9.02112C17.0112 8.57239 17.0112 7.84485 16.5625 7.39612C16.1138 6.94739 15.3862 6.94739 14.9375 7.39612L8.13268 14.2009C7.94532 14.3883 7.85164 14.482 7.78464 14.5913C7.72524 14.6882 7.68147 14.7939 7.65493 14.9045C7.625 15.0291 7.625 15.1616 7.625 15.4266V16.3336Z"
      stroke={color}
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default EditIcon;

// categoriesReducer.ts
import { CategoriesActionTypes } from "./categoriesActionTypes";
import { CategoriesState } from "./categoriesInterfaces";

const initialState: CategoriesState = {
  categories: [],
  categoryItems: null,
  loading: false,
  error: null,
};

const categoriesReducer = (
  state = initialState,
  action: any
): CategoriesState => {
  switch (action.type) {
    case CategoriesActionTypes.GET_CATEGORIES_REQUEST:
      case CategoriesActionTypes.GET_CATEGORY_DETAILS_REQUEST:
      return { ...state, loading: true, error: null };

    case CategoriesActionTypes.GET_CATEGORIES_SUCCESS:
      return { ...state, loading: false, categories: action.payload };

      case CategoriesActionTypes.GET_CATEGORY_DETAILS_SUCCESS:
        return {
          ...state,
          loading: false,
          categoryItems: action.payload,
        };

    case CategoriesActionTypes.GET_CATEGORY_DETAILS_FAILURE:
    case CategoriesActionTypes.GET_CATEGORIES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default categoriesReducer;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";
import CloseIconBtn from "../../../../../../.././images/CloseIcon_btn.svg";
import {
  Ingredient,
  MenuItem,
  SelectedIngredient,
} from "../../../../../../../interfaces/Catalogue";
import IngredientItem from "../../../../../Ingredient/IngredientItem/IngredientItem";
import "./IngredientModal.scss";

interface IngredientModalProps {
  menuItem: MenuItem;
  compositionMaxSelection: number;
  ingredients: Ingredient[];
  typeOrder: number;
  updateIngredientSelections: (
    selections: SelectedIngredient[],
    eliminatedIngredients: SelectedIngredient[],
    menuItem: MenuItem
  ) => void;
  ingredientSelections: SelectedIngredient[];
  isOpen: boolean;
  onClose: () => void;
  isCheckedItem: boolean;
}

function IngredientModal(props: IngredientModalProps) {
  const {
    menuItem,
    compositionMaxSelection,
    ingredients,
    typeOrder,
    updateIngredientSelections,
    ingredientSelections,
    isOpen,
    onClose,
    isCheckedItem,
  } = props;

  const initialSelectedIngredients = ingredients
    .filter((ingredient: Ingredient) => ingredient.inclus)
    .map((ingredient: Ingredient) => ({
      id: ingredient.id,
      ingredient,
      quantity: 1,
    }));

  const [selectedIngredients, setSelectedIngredients] = useState<
    SelectedIngredient[]
  >(isCheckedItem ? ingredientSelections : initialSelectedIngredients);

  const [eliminatedIngredients, setEliminatedIngredients] = useState<
    SelectedIngredient[]
  >([]);

  const handleClose = () => {
    onClose();
  };

  const handleSubmitIngredient = async () => {
    updateIngredientSelections(selectedIngredients,eliminatedIngredients, menuItem);
  };

  const updateCheckedCompositions = (
    ingredientId: number,
    ingredient: Ingredient,
    quantity: number
  ) => {
    if(ingredient.isOutOfStock) return;

    if(ingredient.inclus){
      const eliminatedIngredient = initialSelectedIngredients.find(item => item.id === ingredientId);
      if (eliminatedIngredient) {
        setEliminatedIngredients(
        (prev) => {
          const existing = prev.find((item) => item.id === ingredientId);
          if (existing) {
            return prev.filter((item) => item.id !== ingredientId);
          }
          return [...prev, { id: ingredientId, ingredient, quantity }];
        }
        );
      }
    }

    setSelectedIngredients((prev) => {
      const existing = prev.find((item) => item.id === ingredientId);
      if (existing) {
        if (quantity === 0) {
          return prev.filter((item) => item.id !== ingredientId);
        }
        return prev.map((item) =>
          item.id === ingredientId ? { ...item, quantity } : item
        );
      }
      return [...prev, { id: ingredientId, ingredient, quantity }];
    });
  };

  useEffect(() => {
    console.log("useEffect eliminatedIngredients",eliminatedIngredients)
    console.log("useEffect selectedIngredients",selectedIngredients)
  }, [eliminatedIngredients,selectedIngredients])

  const totalSelectedNotInclus = selectedIngredients.reduce(
    (total, item) => (!item.ingredient.inclus ? total + item.quantity : total),
    0
  );

  return (
    <Dialog
      className="CompositionModal"
      fullWidth={true}
      maxWidth="xs"
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle className="TitleModal">Composition</DialogTitle>
      <img
        className="closeModal"
        onClick={handleClose}
        src={CloseIconBtn}
        alt=""
      />

      <DialogContent>
        {ingredients && (
          <div
            className={
              compositionMaxSelection === 1
                ? "compositions-wrapper compositionsContainer"
                : "compositionsContainer"
            }
          >
            {ingredients.map((ingredient) => (
              <IngredientItem
                key={ingredient.id}
                ingredient={ingredient}
                selectedIngredients={selectedIngredients}
                updateCheckedCompositions={updateCheckedCompositions}
                totalSelectedNotInclus={totalSelectedNotInclus}
                compositionMaxSelection={compositionMaxSelection}
                typeOrder={typeOrder}
              />
            ))}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          className="validate"
          onClick={() => {
            handleSubmitIngredient();
            handleClose();
          }}
        >
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default IngredientModal;

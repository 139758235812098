import moment from "moment";
import { Commande } from "../../models/services/module.order";
import { getDiningModeLabel } from "../../utils";

export default function OrderElement({
  order,
  selectOrder,
}: {
  order: Commande;
  selectOrder: (order: Commande) => void;
}) {
  
  const ShowCommandDetails = () => {
    selectOrder(order);
    document
      .querySelector(".commands-wrapper")
      ?.classList.add("ShowCommandDetails");
  };

  return (
    <div className="CommandElement" onClick={ShowCommandDetails}>
      <div className="CommandImg">
        <img
          src={order.imageRestaurant}
          alt=""
        />
      </div>

      <div className="CommandInfo">
        <p>
          <b className="restaurant-name">{order.nomRestaurant}</b>
        </p>
        <p>
          <b className="order-number">Order N° #{order.referenceCommande}</b>
        </p>
        <p className="date-order">
          {moment(order.date).format("DD MMM à HH:mm")}{" "}
          <span className="delivery-type">
          {getDiningModeLabel(order.diningMode)}
          </span>
        </p>
        <p className="order-Price">
          <span className="order-count">{order.nbArticles} article(s)</span>
          <span className="price">• {order.totalPrix}€</span>
        </p>
      </div>
    </div>
  );
}

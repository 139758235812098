import axios from "axios";
import { FormEvent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { NavAuth } from "../../components/Auth/NavAuth/NavAuth";
import withNavigateHook from "../../hooks/withNavigateHook";
import Arrowleft from "../../images/arrow-left_grey.svg";
import {
  clearStoreItem,
  getmyprofile,
  sendVerificationCode,
  updateLoading,
  updateTokens,
  updateUserAuth,
  verifyCode,
} from "../../store/actions/actions";
import "./AuthStyles.scss";

const ForgetPasswordPage = (props: any) => {
  const [dataPasswordAndPhone, setDataPasswordAndPhone] = useState();
  const [selectedContact, setSelectedContact] = useState("");
  const dataFetched = false

  const userInput =
    props.user?.type === "email" ? props.user?.email : props.user?.phone;


  window.addEventListener("popstate", (event) => {
   
    props.navigate("/");
  });

  useEffect(() => {
    if (props.user === null || props.user.length === 0) {
      props.navigate("/Authentification");
    }
  }, []);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedContact(event.target.value);
  };

  const baseUrl = localStorage.getItem("api_baseUrl");

  const postData = async (userData: any) => {
    try {
      const response = await axios.post(
        baseUrl + "/Account/getSecondInputForResetPassword",
        { firstInput: userData },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
     
      return setDataPasswordAndPhone(response.data);
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (!dataFetched && userInput !== undefined) {
      postData(userInput);
    }
  }, []);

  const handleTheEmail = () => {
    if (
      dataPasswordAndPhone &&
      (dataPasswordAndPhone as any)?.erreur === null
    ) {
      return (dataPasswordAndPhone as any)?.message;
    }
  };

  const handleSendCodePassword = async (userData: any) => {
    try {
      const response = await axios.post(
        baseUrl + "/Account/SendCode-PasswordReinitalisation",
        {
          input: userData,
          hashcode: "0000",
          methode: selectedContact == "email" ? 0 : 1,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let contactInfo;

    if (selectedContact === "phone") {
      contactInfo =
        (props.user?.type === "phone" && props.user?.length !== 0) ||
        props.user?.type === "phone"
          ? props.user?.phone
          : handleTheEmail();
   
    } else if (selectedContact === "email") {
      contactInfo =
        (props.user?.type === "email" && props.user?.length !== 0) ||
        props.user?.type === "email"
          ? props.user?.email
          : handleTheEmail();
     
    } else {
      // Handle case when neither radio button is selected
      return;
    }

    try {
      await handleSendCodePassword(props.user?.phone ?? props.user?.email);
      props.navigate("/Authentification/otp-forget-password");
    } catch (error) {
      console.error("Error sending code:", error);
      // Handle error
    }
  };

  return (
    <div className="authPage">
      <NavAuth />

      <div style={{ maxWidth: "450px" }} className="content ForgetPassword">
        <Link className="NavBack" to="/Authentification">
          <img src={Arrowleft} alt="" />
        </Link>
        <p
          style={{ maxWidth: "262px", margin: "0 auto" }}
          className="tite-form"
        >
          Réinitialisation de mot de passe
        </p>
        <p className="description-form">
          Choisissez votre méthode de réinitialisation <br /> du mot de passe .
        </p>
        <form onSubmit={handleSubmit}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "30px" }}
          >
            <div className="col-inp">
              <input
                type="radio"
                id="contactChoice2"
                name="contact"
                value="phone"
                checked={selectedContact === "phone"}
                onChange={handleRadioChange}
              />
              <div className="inp-info">
                <p className="label-info">Envoi par SMS</p>
                <p className="value-info">
                  {(props.user?.type === "email" && props.user?.length !== 0) ||
                  props.user?.type === "email"
                    ? handleTheEmail()
                    : props.user?.phone}
                </p>
              </div>
            </div>

            <div className="col-inp">
              <input
                type="radio"
                id="contactChoice1"
                name="contact"
                value="email"
                checked={selectedContact === "email"}
                onChange={handleRadioChange}
              />
              <div className="inp-info">
                <p className="label-info">Envoi par Email</p>
                <p className="value-info">
                  {(props.user?.type === "phone" && props.user?.length !== 0) ||
                  props.user?.type === "phone"
                    ? handleTheEmail()
                    : props.user?.email}
                </p>
              </div>
            </div>
          </div>
          <div>
            <button className="submit" type="submit">
              Continuer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
    resendVerificationCodeResponse: state.auth.resendVerificationCodeResponse,
    verifyCodeSuccess: state.auth.verifyCodeSuccess,
    sendVerificationCodeResponse: state.auth.sendVerificationCodeResponse,
    authRetries: state.auth.authRetries,
    authError: state.auth.authError,
    validationStepToken: state.auth.validationStepToken,
    validationSignupToken: state.auth.validationSignupToken,
    userRedirectAfterLoginTo: state.auth.userRedirectAfterLoginTo,
    userInfo: state.auth.userInfo,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateUserInfo: (userInfo: any) => dispatch(updateUserAuth(userInfo)),
    sendVerificationCode: (
      body: serviceAuthModels.SendVerificationCodeBody,
      resend: boolean
    ) => dispatch(sendVerificationCode(body, resend)),
    updateLoading: (value: boolean) => dispatch(updateLoading(value)),
    verifyCode: (body: serviceAuthModels.verifyCodeBody) =>
      dispatch(verifyCode(body)),
    clearItemStore: (item: string) => dispatch(clearStoreItem(item)),
    updateTokens: (verifToken: string, signupToken: string, token: any) =>
      dispatch(updateTokens(verifToken, signupToken, token)),
    getmyprofile: () => dispatch(getmyprofile()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigateHook(ForgetPasswordPage));

import { applyMiddleware, createStore, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import authReducer from "./reducers/authReducer";
import viewReducer from "./reducers/viewReducer";
import shopReducer from "./reducers/shopReducer";
import categoryReducer from "./reducers/categoryReducer";
import menuReducer from "./reducers/menuReducer";
import cartReducer from "./reducers/cartReducer";
import orderReducer from "./reducers/orderReducer";
import paymentReducer from "./reducers/paymentReducer";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import rootSaga from "./sagas/saga";
import categoriesReducer from "./V2/categories/categoriesReducer";

const bindMiddleware = (middleware) => {
  const { composeWithDevTools } = require("redux-devtools-extension");
  // TODO: change this to "DEV"
  // const isDev = process.env.NODE_ENV === "development";
  
  // if (isDev) {
  //   const { composeWithDevTools } = require("redux-devtools-extension");
  //   return composeWithDevTools(applyMiddleware(...middleware));
  // } else {
    return applyMiddleware(...middleware);
  // }
};

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["location", "userInfo", "token"],
};

const persistConfigCart = {
  key: "cart",
  storage: storage,
  whitelist: ["savedCart", "savedCategories"],
};

const persistedReducer = persistReducer(persistConfig, authReducer);
const persistedReducerCart = persistReducer(persistConfigCart, cartReducer);

const rootReducer = combineReducers({
  auth: persistedReducer,
  view: viewReducer,
  shop: shopReducer,
  category: categoryReducer,
  menu: menuReducer,
  cart: persistedReducerCart,
  order: orderReducer,
  payment: paymentReducer,
  categories: categoriesReducer,
});

function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware({});

  const store = createStore(
    rootReducer,
    initialState,

    bindMiddleware([sagaMiddleware]),
    { trace: true },
    typeof window.__REDUX_DEVTOOLS_EXTENSION__ === "undefined"
      ? (a) => a
      : window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
  );

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
}

export default configureStore;

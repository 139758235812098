import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { languageData } from "../../../../i18n";
import CloseIcon from "../../../../images/CloseIcon_btn.svg";
import RestaurantIcon from "../../../../images/RestaurantIcon.svg";
import PanierIcon from "../../../../images/shopping-bag-01.svg";
import { Cart, RestaurantCart } from "../../../../interfaces/Catalogue";
import { shopApi } from "../../../../services/apiGate";
import {
  pushCategory,
  selectCategory,
} from "../../../../store/actions/actions";
import AuthLoginModal from "../../../shared/Modals/AuthLoginModal/AuthLoginModal";
import CartData from "./CartData/CartData";
import "./cartModalStyles.scss";

const CartModal = (props: any) => {
  const navigate = useNavigate();
  const { restaurantId } = useParams();
  const savedCategories = useSelector(
    (state: { cart: Cart }) => state.cart.savedCategories
  );
  const restaurantCart: RestaurantCart =
    savedCategories[Number(restaurantId)] || {};
  const [ShowCommandDetails, setShowCommandDetails] = useState(true);
  const [showAuth, setShowAuth] = useState(false);
  const [ShowRestaurantError, setShowRestaurantError] = useState(false);

  useEffect(() => {
    if (restaurantId && restaurantCart?.categories?.length === 0) {
      setShowCommandDetails(false);
    }
  }, [restaurantId, savedCategories]);

  useEffect(() => {
    if (
      restaurantCart?.categories &&
      restaurantCart?.categories?.length > 0 &&
      localStorage.getItem("redirectedToPrevPage") === "true"
    ) {
      setShowCommandDetails(true);
    } else {
      setShowCommandDetails(false);
    }
  }, [restaurantId]);

  useEffect(() => {
    const body = document.body;
    if (ShowCommandDetails) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
    return () => {
      body.style.overflow = "auto";
    };
  }, [ShowCommandDetails]);

  const handleCommand = async (restaurantId: number) => {
    let disponible = await checkRestaurantDisponibilty(restaurantId);
    if (disponible) {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        localStorage.setItem("redirectedToAuth", "true");
        navigate("/Authentification");
      } else {
        localStorage.setItem("redirectedToAuth", "false");
        navigate("/paiement");
      }
    } else {
      setShowRestaurantError(!ShowRestaurantError);
    }
  };

  const checkRestaurantDisponibilty = async (restaurantId: number) => {
    const restaurantApi = await shopApi.getResturantById({
      id: restaurantId,
      diningMode: props.mode,
      adresse: {
        latitude: props.location?.lat,
        longitude: props.location?.lng,
      },
    });
    return (
      restaurantApi.data?.restaurant?.isEnabled &&
      restaurantApi.data?.restaurant?.isOpen
    );
  };

  let totalQuantity: number = 0;

  if (restaurantCart?.categories) {
    totalQuantity = restaurantCart.categories.reduce((acc, category) => {
      const quantity = category?.quantity ?? 0;
      return acc + quantity;
    }, 0);
  }

  return (
    <div className={`CartNav ${ShowCommandDetails ? "showBox" : ""}`}>
      <div
        className="panier-btn"
        onClick={() => {
          setShowCommandDetails(!ShowCommandDetails);
          localStorage.setItem("redirectedToPrevPage", "false");
        }}
      >
        <div className="panier-el">
          <img src={PanierIcon} alt="" />
          <p className="alias-panier">Panier</p>
          <b style={{ color: "white" }}>•</b>
        </div>
        <p className="products-count">{totalQuantity}</p>
      </div>

      <CartData
        setShowCommandDetails={setShowCommandDetails}
        ShowCommandDetails={ShowCommandDetails}
        restaurantCart={restaurantCart}
        handleCommand={handleCommand}
      />

      <div className={`AuthModal ${showAuth ? "ShowAuth" : ""}`}>
        <div
          className="overlay-black"
          onClick={() => {
            setShowAuth(!showAuth);
            localStorage.setItem("redirectedToPrevPage", "false");
          }}
        ></div>

        <AuthLoginModal />
      </div>
      <div className={`restaurantError ${ShowRestaurantError ? "" : "hide"}`}>
        <div
          className="overlay-modal"
          onClick={() => setShowRestaurantError(!ShowRestaurantError)}
        ></div>
        <div className="modalContent">
          <div className="wrapper">
            <img
              className="close-btn"
              src={CloseIcon}
              alt=""
              onClick={() => setShowRestaurantError(!ShowRestaurantError)}
            />
            <div>
              <p className="modalTitle">
                {languageData.fr.ModalRestaurantError.modalTitle}
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={RestaurantIcon} alt="" />
                <p className="modalDescription">
                  {languageData.fr.ModalRestaurantError.ModalDescription}
                </p>
              </div>
            </div>

            <button
              onClick={() => setShowRestaurantError(!ShowRestaurantError)}
            >
              {languageData.fr.ModalRestaurantError.ModalButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    location: state.auth.location,
    activeRestaurantId: state.menu.activeRestaurantId,
    preSavedCart: state.cart.preSavedCart,

    menuId: state.menu.menuId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    selectCategory: (
      restaurantCart: serviceMenuModels.Category,
      menuId: number,
      restaurantId: number
    ) => dispatch(selectCategory(restaurantCart, menuId, restaurantId)),
    pushCategory: (restaurantCart: serviceMenuModels.Category) =>
      dispatch(pushCategory(restaurantCart)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartModal);

import { RestaurantCart } from "../../../../../interfaces/Catalogue";
import PanierItem from "../PanierItem/PanierItem";

interface PanierListProps {
  restaurantCarts: RestaurantCart[];
  showRestaurantCart: (restaurantCart: RestaurantCart) => void;
}

const PanierList = (props: PanierListProps) => {
  const { restaurantCarts, showRestaurantCart } = props;

  return (
    <div>
      {restaurantCarts.map((restaurantCart: any, index: number) => (
        <PanierItem
          key={index}
          restaurantCart={restaurantCart}
          showRestaurantCart={showRestaurantCart}
  
        />
      ))}
    </div>
  );
};

export default PanierList;

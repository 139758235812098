import { Card, Link } from "@mui/joy";
import { Skeleton } from "@mui/material";

const MenuItemSkeleton = () => {
  return (
    <Card
      variant="plain"
      sx={{
        height: "100%",
        boxShadow: "md",
        width: "100%",
        padding: 0,
        gap: 0.5,
      }}
    >
      <Link>
        <div className="card-container skeleton">
          <div>
            <Skeleton variant="rectangular" width="100%" height={150} />
            <div style={{ padding: 5 }}>
              <Skeleton variant="text" width="50%" height={30} />
              <Skeleton variant="text" width="10%" height={20} />
              <Skeleton variant="text" width="80%" height={30} />
            </div>
          </div>
        </div>
      </Link>
    </Card>
  );
};

export default MenuItemSkeleton;

import { Link, useNavigate } from "react-router-dom";
import { useConfig } from "../../../context/ConfigContext";
import { isColorDark } from "../../../utils/colorsUtils";

export function NavAuth() {
  const navigate = useNavigate();
  const { config } = useConfig();
  const logo = isColorDark(config?.layoutColor || "") ? config?.appLightLogoImage : config?.appDarkLogoImage;

  return (
    <div className="nav-auth">
      <div className="nav-wrapper">
        <Link to="/" onClick={(e) => e.stopPropagation()}>
          <img
            src={logo}
            alt=""
            onClick={() => {
              navigate("/");
            }}
          />
        </Link>
      </div>
    </div>
  );
}
